import { useEffect, useState } from "react";
import { InputCodeBox, InputCodeButton, InputCodeContainer, SubModalError, TimerContainer } from "./index.style";
import SubModal from "../Modal/SubModal";
import { WarningCircleIcon } from "constants/icons";
import { palette } from "constants/theme";
import { getCheckAuth } from "core/util/api/offlineQr";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { tvEnvState } from "core/store/tvEnvState";

const InputCode = ({
  DUID,
  expiredHandler,
  cannotAccessHandler,
}: {
  DUID: string | null;
  expiredHandler: () => void;
  cannotAccessHandler: () => void;
}) => {
  const { SCENARIO } = useRecoilValue(tvEnvState);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [isCodeIncorrect, setIsCodeIncorrect] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const { t } = useTranslation();

  const navigate = useNavigate();

  const codeCheck = async () => {
    if (!DUID) {
      return;
    }

    const { isSuccess, data } = await getCheckAuth(DUID, code);

    if ((isSuccess && data.status) || !isSuccess) {
      switch (data.status) {
        case 480: // InvalidDuid
          break;
        case 481: // WrongAuthCode
          setIsCodeIncorrect(true);
          break;
        case 482: // AlreadyInUse
          cannotAccessHandler();
          break;
        default:
          break;
      }

      return;
    }

    navigate(`/${SCENARIO}?duid=${DUID}&deviceId=${data.deviceId}`);
    return;
  };

  const expiredModalHandler = () => {
    expiredHandler();
  };

  const incorrectCodeModalHandler = () => {
    setCode("");
    setIsCodeIncorrect(false);
  };

  const codeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  useEffect(() => {
    setIsActive(code.length > 0);
  }, [code]);

  return (
    <>
      <InputCodeContainer>
        <div>{t("offlineqr_code_guide")}</div>
        <InputCodeBox>
          <input type="number" placeholder="input" value={code} maxLength={2} onChange={codeHandler} />
          <Timer setIsExpired={setIsExpired} />
        </InputCodeBox>
        <InputCodeButton $isActive={isActive} onClick={codeCheck}>
          {t("set_ok")}
        </InputCodeButton>
      </InputCodeContainer>

      {isExpired && <ErrorModal modalCloseHandler={expiredModalHandler} errorMsg={t("offlineqr_code_error_expired")} />}

      {isCodeIncorrect && (
        <ErrorModal modalCloseHandler={incorrectCodeModalHandler} errorMsg={t("offlineqr_code_error_incorrect")} />
      )}
    </>
  );
};

export default InputCode;

const Timer = ({ setIsExpired }: { setIsExpired: any }) => {
  const [time, setTime] = useState<number>(60);
  const minutes = "0" + Math.floor(time / 60).toString();
  const remain = time % 60;
  const seconds = remain < 10 ? "0" + remain.toString() : remain;

  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime(time - 1);
      }, 1000);
      return () => clearInterval(interval);
    }

    setIsExpired(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <TimerContainer>
      {minutes}:{seconds}
    </TimerContainer>
  );
};

const ErrorModal = ({ errorMsg, modalCloseHandler }: { errorMsg: string; modalCloseHandler: () => void }) => {
  const { t } = useTranslation();

  return (
    <SubModal isModalOpen={true}>
      <SubModalError>
        <WarningCircleIcon color={palette.grayScale.dark_80} />
        <div>{errorMsg}</div>
        <button onClick={modalCloseHandler}>{t("set_ok")}</button>
      </SubModalError>
    </SubModal>
  );
};
