import { useTheme } from "core/contexts/ThemeContext";
import Button from "components/common/DefaultBtn";
import { WelcomeContainer, WelcomeTitle, WelcomeTypo, WelcomeWrapper } from "./index.style";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { tvEnvState } from "core/store/tvEnvState";
import { useEffect, useState } from "react";

interface IProps {
  startHandler: () => void;
}

const CoverLayout = ({ startHandler }: IProps) => {
  const { SCENARIO } = useRecoilValue(tvEnvState);
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [coverTitle, setCoverTitle] = useState<string>("");

  useEffect(() => {
    setCoverTitle(SCENARIO === "sound" ? "" : `${SCENARIO}_cover`);
  }, [SCENARIO]);

  return (
    <WelcomeContainer $bgImage={theme.CoverLayout.image}>
      <WelcomeWrapper>
        <WelcomeTitle>
          <div>{t("ai_cover_title")}</div>
          {coverTitle.length > 0 && <div>{t(coverTitle)}</div>}
        </WelcomeTitle>
        <WelcomeTypo>{t(`main_cover_${SCENARIO}`)}</WelcomeTypo>
        <Button isDefault={true} handler={startHandler}>
          {t("common_start")}
        </Button>
      </WelcomeWrapper>
    </WelcomeContainer>
  );
};

export default CoverLayout;
