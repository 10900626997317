import { Dispatch, SetStateAction, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { BNBContainer, BNBElement, BNBTitle } from "./index.style";
import { LineUpIcon, OnlineIcon, SizeSimulatorIcon, SmartThings } from "constants/icons";
import TvLineupModal from "layout/Modal/TvLineupModal";
import SizeSimulatorModal from "layout/Modal/SizeSimulatorModal";
import { useTheme } from "core/contexts/ThemeContext";
import { useSocket } from "core/contexts/SocketContext";
import { stepState } from "core/store/stepState";
import { STEP } from "constants/enum/step";
import { SCENARIO_STEP } from "constants/enum/scenarioStep";
import useTimeout from "core/hooks/useTimer";
import { linkState } from "core/store/linkState";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import { setUrl } from "core/util/url";
import useHeight from "core/hooks/useHeight";
import { postLinkAccess } from "core/util/api/tvApi";
import { tvEnvState } from "core/store/tvEnvState";

const BNB = ({ setIsSNBOpen }: { setIsSNBOpen: Dispatch<SetStateAction<boolean | null>> }) => {
  const [isSizeModalOpen, setIsSizeModalOpen] = useState<boolean | null>(null);
  const [isLineUpOpen, setIsLineUpOpen] = useState<boolean | null>(null);
  const [step, setStepState] = useRecoilState(stepState);
  const [view, setView] = useState<string>("");
  const { theme } = useTheme();
  const { isUnder } = useHeight();
  const { DUID } = useRecoilValue(tvEnvState);
  const { pdp, smartthings } = useRecoilValue(linkState);
  const { scenarioStep, scenarioExperience, sizeSimulatorStart, sizeSimulatorEnd, tvLineUp, tvLineEnd } = useSocket();
  const { setAsync } = useTimeout();
  const { t } = useTranslation();

  const BNBElements = [
    {
      title: "bnb_screen_size_simulator",
      icon: <SizeSimulatorIcon color={theme.color.icon.primary} />,
      handler: () => {
        setIsSNBOpen(false);
        sizeSimulatorStart();
        setIsSizeModalOpen(true);
      },
    },
    {
      title: "bnb_tv_line_up",
      icon: <LineUpIcon color={theme.color.icon.primary} />,
      handler: () => {
        setIsSNBOpen(false);
        tvLineUp();
        setIsLineUpOpen(true);
      },
    },
    {
      title: "bnb_smartthings",
      icon: <SmartThings color={theme.color.icon.primary} />,
      handler: () => {
        setIsSNBOpen(false);
        postLinkAccess(DUID, "smartthings");
        window.open(setUrl(smartthings), "_blank", "noreferrer");
      },
    },
    {
      title: "bnb_online",
      icon: <OnlineIcon color={theme.color.icon.primary} />,
      handler: () => {
        setIsSNBOpen(false);
        postLinkAccess(DUID, "productdetailpage");
        window.open(setUrl(pdp), "_blank", "noreferrer");
      },
    },
  ];

  const closeModalSocket = () => {
    if (step.step === STEP.OUTRO) {
      setStepState((prev) => ({
        step: prev.step,
        scenarioStep: SCENARIO_STEP.START,
      }));
      scenarioStep(step.step);
    } else {
      setStepState((prev) => ({
        step: prev.step,
        scenarioStep: SCENARIO_STEP.EXPERIENCE,
      }));
      scenarioExperience(step.step, true);
    }
  };

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setView("");
        }}
      >
        <BNBContainer $isOutro={step.step === "outro"} $isUnder={isUnder}>
          {BNBElements.map((elem, idx) => {
            return (
              <BNBElement onClick={() => view === elem.title && elem.handler()} key={idx}>
                <div
                  onClick={() => {
                    setView(elem.title);
                  }}
                >
                  {elem.icon}
                </div>
                {view === elem.title && <BNBTitle>{t(elem.title)}</BNBTitle>}
              </BNBElement>
            );
          })}
        </BNBContainer>
      </OutsideClickHandler>

      <TvLineupModal
        isModalOpen={isLineUpOpen}
        modalCloseHandler={(value) => {
          setIsLineUpOpen(value);
          if (value !== null) return;
          tvLineEnd();
          closeModalSocket();
        }}
      />

      <SizeSimulatorModal
        isModalOpen={isSizeModalOpen}
        modalCloseHandler={async (value) => {
          setIsSizeModalOpen(value);
          if (value !== null) return;
          sizeSimulatorEnd();
          await setAsync(500);
          closeModalSocket();
        }}
      />
    </>
  );
};

export default BNB;
