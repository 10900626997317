import { palette } from "constants/theme";
import { typoOne700C } from "constants/theme/fonts";
import styled from "styled-components";

export const ButtonContainer = styled.button<{ $isDefault: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  min-width: 139px;
  height: 44px;
  padding: 14px 45px;

  border-radius: 23px;
  border: solid 2px
    ${(props) =>
      props.$isDefault ? palette.grayScale.dark_0 : props.theme.mainColor};
  background-color: ${palette.grayScale.dark_0};
  color: ${palette.grayScale.dark_80};

  ${typoOne700C}
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 1.4px;
`;
