export const palette = {
  grayScale: {
    dark_0: "#FFFFFF",
    dark_5: "#EEEEEE",
    dark_10: "#BFBFBF",
    dark_15: "#9F9F9F",
    dark_20: "#9A9A9A",
    dark_25: "#888888",
    dark_30: "#707070",
    dark_35: "#525457",
    dark_40: "#4D4D4D",
    dark_45: "#404040",
    dark_50: "#3A3A3A",
    dark_55: "#313131",
    dark_60: "#2D2D2D",
    dark_65: "#202020",
    dark_70: "#161515",
    dark_75: "#0D0D0D",
    dark_80: "#000000",
  },
  secondary: {
    red: "#FF0000",
    lightBlue: "#81B3FF",
    navy_5: "#3D4450",
    navy_10: "#1C222D",
    navy_15: "#1E2024",
  },
  picture: {
    yellow: "#FFD12E",
    ochre: "#A88A1F",
    bg: "#222623",
  },
  sound: {
    magenta: "#E5199A",
    blue: "#3037DB",
  },
  oled: {
    aqua: "#04FFD2",
    lightEmerald: "#3AE8DD",
    emerald: "#2C9790",
    darkSeaBlue: "#123E41",
  },
  smartthings: {
    skyblue: "#5BA7EE",
  },
} as const;

export const zindex = {
  rotatePhone: 1000,
  SNB: 170,
  modalTopEnd: 160,
  modalMask: 150,
  cover: 130,
  modalMid: 130,
  subModal: 200,
  common: 100,
  volumeBtn: 120,
  volumeBtnUnder: 119,
  modal: 110,
  modalClose: -100,
  background: -100,
} as const;
