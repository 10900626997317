import { palette } from "constants/theme";
import { typoOne500C, typoSharpSansBold } from "constants/theme/fonts";
import { MainTitle, SubTitle, TitleContainer } from "constants/theme/globalStyles";
import styled, { css, keyframes } from "styled-components";

export const moveAnimation = keyframes`
    0% { transform: translateY(0);
}
    100% { transform: translateY(-70px);
      opacity : 0;
}
`;

const activeButtonStyle = css`
  background-color: ${palette.grayScale.dark_0};
  color: ${(props) => props.theme.mainColor};
  opacity: 1;
  border: ${(props) => props.theme.mainColor} solid 1px;
`;

const notActiveButtonStyle = css`
  background-color: ${palette.grayScale.dark_5};
  color: ${palette.grayScale.dark_15};
  opacity: 0.9;
`;

export const BottomButtonBox = styled.div<{ $isQSymphonyOn: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;

  > button {
    width: 133px;
    height: 33px;

    ${typoSharpSansBold}
    font-size: 14px;
    border: none;
    border-radius: 20px;
  }

  > button:nth-of-type(1) {
    ${(props) => (props.$isQSymphonyOn ? notActiveButtonStyle : activeButtonStyle)}
  }
  > button:nth-of-type(2) {
    ${(props) => (props.$isQSymphonyOn ? activeButtonStyle : notActiveButtonStyle)}
  }
`;

export const BottomBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;

  width: 100%;
  height: 23px;

  > div {
    width: 45px;
    height: 1px;

    border: ${(props) => props.theme.mainColor} solid 1px;
    border-radius: 1px;
    background-color: ${(props) => props.theme.mainColor};
  }
`;

export const BottomMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 17px;

  position: absolute;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 121px;

  background-color: ${palette.grayScale.dark_50};
  border-radius: 20px 20px 0 0;

  > div {
    ${typoSharpSansBold}
  }
`;

export const SwipeGuide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: calc(20% + 8px);

  width: 100%;

  > svg {
    transform: rotate(0.25turn);
  }

  > div {
    position: relative;
    top: -10px;

    ${typoOne500C}
    font-size: 11px;
    line-height: 145%;
    letter-spacing: 1.5px;
  }
`;

export const MovieTitle = styled.div`
  position: absolute;
  top: 32%;

  width: 100%;

  ${typoSharpSansBold}
  font-size:20px !important;
  line-height: 170% !important;
  letter-spacing: 1px !important;
`;

export const MovieImage = styled.div`
  height: 80%;

  border-radius: 30px;
  object-fit: cover;
  overflow: hidden;

  > img {
    width: 100%;
    height: auto;
  }
`;

export const AnimationImage = styled.div`
  position: absolute;
  top: 0;

  height: 80%;

  border-radius: 30px;
  object-fit: cover;
  overflow: hidden;

  > img {
    height: 100%;
  }

  > img {
    filter: blur(0.8rem);
  }

  -webkit-animation: ${moveAnimation} 0.5s ease;
  animation: ${moveAnimation} 0.5s ease;
`;

export const MovieCard = styled.div<{
  $isPlay: boolean;
  $isSelected: boolean;
  $isCoach: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 22px;

  position: relative;

  width: auto;
  height: 100%;
  margin-inline: 10px;
  border-radius: 30px;

  > div {
    text-align: center;
    ${typoSharpSansBold}
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 0.75px;
  }

  ${(props) =>
    props.$isPlay &&
    (props.$isSelected
      ? css`
          > ${MovieImage} > img {
            filter: blur(0.8rem);
          }
        `
      : css`
          opacity: 0.61;
        `)}

  ${(props) =>
    props.$isCoach
      ? css`
          > ${MovieImage} > img {
            filter: blur(0.8rem);
          }
        `
      : css`
          opacity: 0.61;
        `}
`;

export const MovieContainer = styled.div`
  display: flex;

  width: 100vw;
  height: calc(100% - 240px);

  overflow: visible;

  .flicking-camera {
    align-items: flex-start;
    justify-content: center;

    overflow: visible;
  }

  .flicking-viewport:not(.vertical) {
  }

  > * {
    overflow: visible;
    border-radius: 30px;
  }
`;

export const HomeCinemaContainer = styled.div`
  height: calc(100% - 114px);
  padding-top: 114px;

  ${TitleContainer} {
    margin-bottom: 20px;
  }

  ${MainTitle} {
    color: ${(props) => props.theme.mainColor};
  }
  ${SubTitle} {
    color: ${palette.grayScale.dark_0};
  }
`;
