import { memo, useState } from "react";
import { CoachContainer, SwipeContainer } from "./Index.style";
import { Swipe } from "constants/icons/coach";
import Pointed from "./Pointed";
import { CoachProps } from "types/common";

const HomecinemaCoach = ({ coachHandler }: CoachProps) => {
  const [touchStartY, setTouchStartY] = useState<number | null>(null);

  const coachHandlerStart = (e: React.TouchEvent) => {
    if (touchStartY !== null) {
      setTouchStartY(null);
    }
  };

  const coachHandlerEnd = (e: React.TouchEvent) => {
    if (e.touches.length === 1) {
      setTouchStartY(e.touches[0].clientY);
      coachHandler();
    }
  };

  return (
    <CoachContainer
      className="homecinema"
      onTouchStart={coachHandlerStart}
      onTouchMove={coachHandlerEnd}
      onTouchEnd={coachHandlerEnd}
    >
      <SwipeContainer>
        <Swipe />
        <Pointed
          positionCss={`
          position:relative;
          top: -65px;
          left: 10px;
        `}
          fingerfilled={true}
        />
      </SwipeContainer>
    </CoachContainer>
  );
};

export default memo(HomecinemaCoach);
