import { memo } from "react";
import { MenuButton, MenuCoachBox, MenuContainer } from "./index.style";
import { MenuButtonProps } from "types/common";
import { palette } from "constants/theme";
import { MenuIcon } from "constants/icons";
import { useTranslation } from "react-i18next";

const Menu = ({ isCoach, setIsSNBOpen }: MenuButtonProps) => {
  const { t } = useTranslation();

  return (
    <MenuContainer>
      <MenuButton
        $isCoach={isCoach}
        onClick={() => {
          !isCoach && setIsSNBOpen && setIsSNBOpen(true);
        }}
      >
        <MenuIcon color={palette.grayScale.dark_80} />
      </MenuButton>
      {isCoach && <MenuCoachBox>{t("coach_menu")}</MenuCoachBox>}
    </MenuContainer>
  );
};

export default memo(Menu);
