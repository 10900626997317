import { palette, zindex } from "constants/theme";
import { typoSharpSansRegular } from "constants/theme/fonts";
import styled from "styled-components";

export const AssetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  width: 318px;
  height: 174px;

  background-color: ${palette.grayScale.dark_70};
  border-radius: 12px;

  text-align: center;
  ${typoSharpSansRegular}
  font-size: 17px;
  line-height: 135%;
`;

export const RotatePhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zindex.rotatePhone};

  width: 100vw;
  height: 100vh;

  background-color: ${palette.grayScale.dark_65};
`;
