import styled, { createGlobalStyle } from "styled-components";

import reset from "styled-reset";
import { palette } from ".";
import { typoOne400C, typoSharpSansBold, typoSharpSansRegular } from "./fonts";

const GlobalStyle = createGlobalStyle<{ $subsidiary: string }>`
  ${reset}

  body {
    display: block;

    max-width: 100%;
    max-height: 100vh;
    margin: 0;
    padding: 0;
    border: 0;

    overflow: hidden !important;
    touch-action: none;
    overscroll-behavior: none;

    background-color: ${palette.grayScale.dark_80};
    color: ${palette.grayScale.dark_0};
    --samsung-sharpsans: 'SamsungSharpSans';
    --samsung-one: 'SamsungOne';
    --samsung-one-country: 'SamsungOneKorean';

    ${(props) =>
      props.$subsidiary === "ko" &&
      `
      word-break: keep-all;
    `}

    ${(props) =>
      props.$subsidiary === "cn" &&
      `
      --samsung-sharpsans: 'SamsungOneChina';
      --samsung-one: 'SamsungOneChina';
      --samsung-one-country: 'SamsungOneChina';
      `}

    box-sizing: border-box;
    white-space:pre-wrap;
  }

  #root {
    position:absolute;
    top:0;
    left:0;

    width:100%;
    height:100%;
  }

  .coverBorder {
    ${typoSharpSansBold}
  }
`;

export default GlobalStyle;

// TITLE -----------------------------------
export const ExplainText = styled.div`
  ${typoOne400C}
  font-size: 14px;
  text-align: center;
  line-height: 160%;
  color: ${palette.grayScale.dark_10};

  opacity: 0.8;
`;

export const MainTitle = styled.div`
  margin-bottom: 16px;

  ${typoSharpSansBold}
  color: ${palette.grayScale.dark_0};
  font-size: 26px;
  line-height: 130%;
  text-align: center;
`;

export const SubTitle = styled.div`
  ${typoSharpSansRegular}
  font-size: 20px;
  line-height: 190%;
  color: ${(props) => props.theme.mainColor};
  text-align: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// CONTAINERS -----------------------------------
export const Container = styled.div`
  width: 100%;
  height: 100%;
`;
