export enum EVENTS {
  CREATE_ROOM = "CREATE_ROOM",
  JOIN_ROOM = "JOIN_ROOM",
  DELETE_ROOM = "DELETE_ROOM",
  TV_REGISTER = "TV_REGISTER",

  ACTIVE_MONITOR = "ACTIVE_MONITOR",
  SCENARIO_MOBILE_TO_TV = "SCENARIO_MOBILE_TO_TV",
  SCENARIO_TV_TO_MOBILE = "SCENARIO_TV_TO_MOBILE",
  RUN_NPP = "RUN_NPP",
  RUN_GDS = "RUN_GDS",

  VOLUME = "VOLUME",
  SIZE_SIMULATOR = "SIZE_SIMULATOR",
  SIZE_SIMULATOR_END = "SIZE_SIMULATOR_END",
  LINE_UP = "LINE_UP",
  LINE_UP_END = "LINE_UP_END",
  MORE_FEATURE = "MORE_FEATURE",
  SCENARIO_END = "SCENARIO_END",
}
