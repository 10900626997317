import { palette, zindex } from "constants/theme";
import { fadeInUpToDown, fadeOutUpToDown } from "constants/theme/animation";
import styled from "styled-components";

export const UnderLine = styled.hr`
  width: 23px;
  margin-block: 0;

  border: none;
  border-top: solid 0.5px ${palette.grayScale.dark_20};
  border-radius: 0.5px 0.5px 0.5px 0.5px;
`;

export const UnderButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 40px;

  background-color: transparent;
  border: none;

  > svg {
    position: relative;
    left: 1px;
  }
`;

export const UnderContainer = styled.div<{ $isUnderOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: absolute;
  top: 0;
  z-index: ${zindex.volumeBtnUnder};

  width: 44px;
  height: calc(132px - (56px + 18px));
  padding-block: 56px 18px;

  border-radius: 90px;
  background: linear-gradient(
    359deg,
    ${(props) => props.theme.soundBtn.linear.rightDown} 14.02%,
    ${(props) => props.theme.soundBtn.linear.leftTop} 72.49%
  );
  opacity: ${(props) => (props.$isUnderOpen ? 1 : 0)};
  animation: ${(props) => (props.$isUnderOpen ? fadeInUpToDown : fadeOutUpToDown)} 0.8s;
  /* Firefox */
  -moz-animation: ${(props) => (props.$isUnderOpen ? fadeInUpToDown : fadeOutUpToDown)} 0.8s;
  /* Safari and Chrome */
  -webkit-animation: ${(props) => (props.$isUnderOpen ? fadeInUpToDown : fadeOutUpToDown)} 0.8s;
  -o-animation: ${(props) => (props.$isUnderOpen ? fadeInUpToDown : fadeOutUpToDown)} 0.8s;
`;

export const VolumeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: ${zindex.volumeBtn};

  width: 44px;
  height: 44px;

  border-radius: 50%;
  background-color: ${(props) => props.theme.soundBtn.btnBg};
`;

export const VolumeContainer = styled.div`
  position: relative;
`;
