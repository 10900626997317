import { SCENARIO_STEP } from "constants/enum/scenarioStep";
import { STEP } from "constants/enum/step";
import { atom } from "recoil";

export interface StepStateAtomType {
  step: STEP;
  scenarioStep: SCENARIO_STEP;
}

export const stepState = atom<StepStateAtomType>({
  key: "stepState",
  default: {
    step: STEP.COVER,
    scenarioStep: SCENARIO_STEP.START,
  },
});
