import { css } from "styled-components";

export const typoOne400C = css`
  font-family: var(--samsung-one);
  font-weight: 400;
`;

export const typoOne500C = css`
  font-family: var(--samsung-one);
  font-weight: 500;
`;

export const typoOne600C = css`
  font-family: var(--samsung-one);
  font-weight: 600;
`;

export const typoOne700C = css`
  font-family: var(--samsung-one);
  font-weight: 700;
`;

export const typoOneNormal = css`
  font-family: var(--samsung-one-country);
  font-weight: 400;
`;

export const typoOneBold = css`
  font-family: var(--samsung-one-country);
  font-weight: 700;
`;

export const typoSharpSansRegular = css`
  font-family: var(--samsung-sharpsans);
  font-weight: 400;
`;

export const typoSharpSansMedium = css`
  font-family: var(--samsung-sharpsans);
  font-weight: 500;
`;

export const typoSharpSansMediumBold = css`
  font-family: var(--samsung-sharpsans);
  font-weight: 600;
`;

export const typoSharpSansBold = css`
  font-family: var(--samsung-sharpsans);
  font-weight: 700;
`;
