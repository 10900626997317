import { useEffect, useState } from "react";

export default function useHeight() {
  const [isUnder, setIsUnder] = useState<boolean>(false);

  useEffect(() => {
    // 초기 설정
    setIsUnder(window.innerHeight >= 720);

    const handleSize = () => {
      if (window.innerHeight === window.screen.height) {
        setIsUnder(true);
      } else {
        setIsUnder(window.innerHeight >= 720);
      }
    };
    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  return { isUnder };
}
