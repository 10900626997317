import { OfflineqrModalProps } from "types/common";
import Modal from "../Modal";

const OfflineQrModal = ({ isModalOpen, children }: OfflineqrModalProps) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      modalCloseHandler={() => {}}
      isCloseBtn={false}
      isOfflineQr={true}
    >
      {children}
    </Modal>
  );
};

export default OfflineQrModal;
