import { memo } from "react";
import { AssetContainer, RotatePhoneContainer } from "./index.style";
import RotateIcon from "assets/icon/RotatePhoneIcon.png";
import { useTranslation } from "react-i18next";

const RotatePhone = () => {
  const { t } = useTranslation();

  return (
    <RotatePhoneContainer>
      <AssetContainer>
        <img src={RotateIcon} alt="Rotate Icon" />
        <>{t("rotate_phone")}</>
      </AssetContainer>
    </RotatePhoneContainer>
  );
};

export default memo(RotatePhone);
