import { palette, zindex } from "constants/theme";
import { fadeInSNB } from "constants/theme/animation";
import {
  typoOne400C,
  typoOne500C,
  typoOne700C,
  typoSharpSansBold,
  typoSharpSansMedium,
  typoSharpSansRegular,
} from "constants/theme/fonts";
import styled, { css } from "styled-components";

export const SNBButtonContainer = styled.button<{ $isHere: boolean }>`
  width: calc(100% + 22px);
  height: 44px;
  padding: 5px 15px;
  margin-bottom: 12px;

  border-radius: 20px 0 0 20px;
  border: solid 2px ${(props) => props.theme.mainColor};
  background-color: ${(props) => (props.$isHere ? props.theme.mainColor : palette.grayScale.dark_0)};

  text-align: left;
  ${(props) => (props.$isHere ? typoSharpSansBold : typoSharpSansMedium)}
  font-size: 14px;
  line-height: 215%;
  color: ${({ $isHere }) => ($isHere ? palette.grayScale.dark_0 : palette.secondary.navy_15)};
  opacity: ${(props) => (props.$isHere ? 1 : 0.6)};

  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
`;

const subButton = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: calc(100% - 20px);
  padding-right: 20px;

  font-size: 12px;
  line-height: 100%;
`;

export const ReplayContainer = styled.div`
  ${subButton}
  position: absolute;
  bottom: 31px;

  ${typoOne700C}

  > svg > path {
    stroke: ${(props) => props.theme.mainColor};
  }
`;

export const ReplayButton = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: transparent;
  border: none;

  color: ${palette.secondary.navy_15};
`;

export const SNBCloseButtonContainer = styled.div`
  ${subButton}
  gap: 16px;

  ${typoOne500C}

  > button {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    width: 44px;
    height: 44px;

    border: none;
    border-radius: 50%;
    background-color: ${palette.secondary.navy_15};

    > svg {
      position: absolute;
      top: calc(18px - 12.5px);
      left: calc(17px - 12.5px);
    }
  }
`;

export const SNBTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: flex-start;

  > div {
    ${typoSharpSansBold}
    font-size: 12px;
    line-height: 100%;
  }

  > hr {
    width: 35px;
    margin: 18.5px 0 12.5px;

    border: solid 1px ${(props) => props.theme.mainColor};
  }
`;

export const SNBContentElement = styled.div`
  ${typoSharpSansRegular}
  font-size: 16px;
  line-height: 150%;

  width: 100%;

  &:nth-of-type(1) > div:nth-of-type(2) {
    opacity: 0.6;

    ${typoOne400C}
    font-size: 16px;
    line-height: 150%;
  }
`;

export const SNBContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;

  width: calc(100% - 20px * 2);
  height: inherit;
  padding: 0 20px;
`;

export const SNBbody = styled.div<{ $isSNBOpen: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${(props) => (props.$isSNBOpen ? zindex.SNB : zindex.modalClose)};

  width: 305px;
  height: calc(100% - 20px * 2 - 7px);
  padding-block: 26.5px 20px;

  background-color: ${palette.grayScale.dark_0};
  color: ${palette.secondary.navy_15};

  opacity: ${(props) => (props.$isSNBOpen ? 1 : 0)};
  animation: ${(props) => (props.$isSNBOpen ? fadeInSNB : null)} 0.8s;
  /* Firefox */
  -moz-animation: ${(props) => (props.$isSNBOpen ? fadeInSNB : null)} 0.8s;
  /* Safari and Chrome */
  -webkit-animation: ${(props) => (props.$isSNBOpen ? fadeInSNB : null)} 0.8s;
  -o-animation: ${(props) => (props.$isSNBOpen ? fadeInSNB : null)} 0.8s;
`;
