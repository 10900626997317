import { palette } from "constants/theme";
import { typoSharpSansRegular } from "constants/theme/fonts";
import { MainTitle } from "constants/theme/globalStyles";
import styled from "styled-components";

export const TvSizeElement = styled.div<{
  $isPrimary: boolean;
  $isCanUse: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 127px;
  height: 127px;
  border-radius: 9px;
  ${(props) =>
    props.$isPrimary
      ? `
    background-color: ${palette.grayScale.dark_80};
    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.34) inset 3px 3px 10px rgba(74, 74, 74, 0.68);
    color: ${palette.grayScale.dark_0};
  `
      : `
    background-color: ${palette.grayScale.dark_0};
    color: ${palette.grayScale.dark_35};
    box-shadow: ${props.$isCanUse ? `none` : `2px 2px 10px #000;`};
    opacity: ${props.$isCanUse ? 1 : 0.5};
  `}

  ${typoSharpSansRegular}
  font-size: ${(props) => (props.$isPrimary ? "50px" : "45px")};
  font-weight: ${(props) => (props.$isPrimary ? "bold" : "normal")};
`;

export const TvSizeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px;

  margin-top: 30px;
`;

export const ChooseTvTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > ${MainTitle} {
    font-size: 30px;
  }
`;
