import { getTvsByDUID } from "core/util/api/tvApi";
import { DEFAULT_LINK, linkState } from "core/store/linkState";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

function useOnlineLink() {
  const { search } = useLocation();
  const DUID = new URLSearchParams(search).get("duid");
  const setOnline = useSetRecoilState(linkState);

  useEffect(() => {
    (async () => {
      const { isSuccess, data } = await getTvsByDUID(DUID as string);

      if (isSuccess) {
        setOnline({
          pdp: data.pdp ?? DEFAULT_LINK,
          smartthings: data.smartthingsLink ?? DEFAULT_LINK,
          magazine: data.magazineLink ?? DEFAULT_LINK,
        });
      }
    })();
  }, []);

  return;
}

export default useOnlineLink;
