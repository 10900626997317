import { memo, useEffect, useState } from "react";
import { Dim, ModalContainer, SubModalBody } from "./index.style";

import { ModalProps } from "types/common";

const SubModal = ({ children, isModalOpen }: ModalProps) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isModalOpen) setOpen(true);
  }, [isModalOpen]);

  if (!open) {
    return null;
  }

  return (
    <ModalContainer
      $isModalOpen={isModalOpen !== null && isModalOpen}
      $isSubModal={true}
    >
      {isModalOpen && <Dim $isSubModal={true} />}
      <SubModalBody>{children}</SubModalBody>
    </ModalContainer>
  );
};

export default memo(SubModal);
