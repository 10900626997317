import { tvEnvState } from "core/store/tvEnvState";
import { getTvsByDUID } from "core/util/api/tvApi";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

const OfflineQrEntry = () => {
  const { DUID } = useRecoilValue(tvEnvState);

  useEffect(() => {
    (async () => {
      const { isSuccess, data } = await getTvsByDUID(DUID);

      if (isSuccess) {
        window.location.href = `${data.Subsidiary.Region.mobileAddr}/offlineQr?duid=${DUID}`;
      }
    })();
  }, [DUID]);

  return <></>;
};

export default OfflineQrEntry;
