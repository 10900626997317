import { useTranslation } from "react-i18next";
import { Body, Header, StyleWrapper } from "./index.style";

interface IProps {
  children: React.ReactNode;
}

const RegisterLayout = ({ children }: IProps) => {
  const { t } = useTranslation();
  return (
    <StyleWrapper>
      <Header>{t("register_header")}</Header>
      <Body>{children}</Body>
    </StyleWrapper>
  );
};

export default RegisterLayout;
