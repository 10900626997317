import { experienceTvType } from "types/common";
import { CompleteBackground, CompleteLayoutContainer, CompleteLayoutTitle, CompleteTextContainer } from "./index.style";
import DefaultBtn from "components/common/DefaultBtn";
import BgOled from "assets/welcome/bg_oled.png";
import BgSound from "assets/welcome/bg_sound.png";
import BgPicture from "assets/welcome/bg_picture.png";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { linkState } from "core/store/linkState";
import { useTranslation } from "react-i18next";
import { postLinkAccess } from "core/util/api/tvApi";
import { tvEnvState } from "core/store/tvEnvState";

const CompleteLayout = ({ scenario }: { scenario: experienceTvType }) => {
  const [bgImg, setBgImg] = useState<string>(BgSound);
  const [header, setHeader] = useState<string>("common_complete_subheader_sound");
  const { DUID } = useRecoilValue(tvEnvState);
  const { pdp } = useRecoilValue(linkState);
  const { t } = useTranslation();

  useEffect(() => {
    switch (scenario) {
      case "oled":
        setBgImg(BgOled);
        setHeader("common_complete_subheader_oled");
        break;
      case "picture8k":
      case "picture4k":
        setBgImg(BgPicture);
        setHeader("common_complete_subheader_picture");
        break;
      case "sound":
        setBgImg(BgSound);
        setHeader("common_complete_subheader_sound");
        break;
      case "smartthings":
        setBgImg(BgOled);
        setHeader("common_complete_subheader_smartthings");
        break;
    }
  }, [scenario]);

  return (
    <>
      <CompleteLayoutTitle>
        {t("common_complete_header")}
        <br />
        <div>{t(header)}</div>
      </CompleteLayoutTitle>
      <CompleteLayoutContainer>
        <CompleteTextContainer>
          <div>
            {t("common_complete_title_1")}
            <br />
            <div>{t("common_complete_title_2")}</div>
          </div>
          <div>{t("common_complete_subtitle")}</div>
        </CompleteTextContainer>
        <DefaultBtn
          handler={() => {
            postLinkAccess(DUID, "productdetailpage");
            window.open(pdp, "_blank", "noreferrer");
          }}
        >
          {t("common_complete_button")}
        </DefaultBtn>
      </CompleteLayoutContainer>
      <CompleteBackground alt={scenario} src={bgImg} $isSound={scenario === "sound"} />
    </>
  );
};

export default CompleteLayout;
