import { useCallback, useState } from "react";

function useLogger(title: string) {
  const [logger, setLogger] = useState<Array<string>>([]);

  const logTime = () => {
    const today = new Date();
    const min = ("0" + today.getMinutes()).slice(-2);
    const sec = ("0" + today.getSeconds()).slice(-2);
    const ms = today.getMilliseconds();

    return `[${min}:${sec}:${ms}]`;
  }

  const ensureLogLength = (logs: string[]) => {
    const maxLogs = 150;
    const overLength = logs.length - maxLogs;
    return overLength > 0 ? logs.slice(overLength) : logs;
  };

  const debug = useCallback((log: string) => {
    setLogger((prevLogs) => {
      const newLogs = ensureLogLength([...prevLogs, `${logTime()}[${title}] ${log}`]);
      return newLogs;
    });
  }, [title]);

  const debugAll = useCallback((logs: string[]) => {
    setLogger((prevLogs) => {
      const newLogs = ensureLogLength([...prevLogs, ...logs.map(log => `${logTime()}[${title}] ${log}`)]);
      return newLogs;
    });
  }, [title]);

  return { logger, debug, debugAll };
}

export default useLogger;
