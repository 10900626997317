import { palette, zindex } from "constants/theme";
import { fadeIn, fadeOut, fadeOutModal } from "constants/theme/animation";
import styled from "styled-components";

export const SubModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: calc(50% - 100px - 24px);
  left: calc(50% - 132px - 18px);
  z-index: ${zindex.modalTopEnd};

  width: 264px;
  min-height: 200px;
  padding: 24px 18px;

  border-radius: 12px;
  background-color: ${palette.grayScale.dark_0};
`;

export const ModalCloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: right;

  position: absolute;
  top: 20px;
  right: 20px;

  background-color: transparent;
  border: none;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const ModalBody = styled.div`
  position: fixed;
  bottom: 0;
  z-index: ${zindex.modalTopEnd};

  width: 100%;
  height: calc(100% - 100px);

  border-radius: 45px 45px 0 0;
  background-color: ${palette.grayScale.dark_70};
`;

export const Dim = styled.div<{ $isSubModal?: boolean }>`
  width: 100%;
  height: 100vh;

  position: relative;
  z-index: ${(props) =>
    props.$isSubModal ? zindex.modalTopEnd : zindex.modalMask};

  background-color: ${palette.grayScale.dark_80};
  opacity: 0.7 !important;
`;

export const ModalContainer = styled.div<{
  $isSubModal?: boolean;
  $isExperience?: boolean;
  $isModalOpen: boolean;
  $isFadeOut?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) =>
    props.$isSubModal
      ? zindex.subModal
      : !props.$isExperience
      ? zindex.modalMid
      : props.$isModalOpen
      ? zindex.modal
      : zindex.modalClose};

  width: 100vw;

  ${ModalBody} {
    opacity: ${(props) => (props.$isModalOpen ? 1 : 0)};
    animation: ${(props) => props.$isFadeOut && fadeOutModal} 0.8s;
    /* Firefox */
    -moz-animation: ${(props) => props.$isFadeOut && fadeOutModal} 0.8s;
    /* Safari and Chrome */
    -webkit-animation: ${(props) => props.$isFadeOut && fadeOutModal} 0.8s;
    -o-animation: ${(props) => props.$isFadeOut && fadeOutModal} 0.8s;
  }

  ${Dim} {
    opacity: ${(props) => (props.$isModalOpen ? 1 : 0)};
    animation: ${(props) => (props.$isModalOpen ? fadeIn : fadeOut)} 0.8s;
    /* Firefox */
    -moz-animation: ${(props) => (props.$isModalOpen ? fadeIn : fadeOut)} 0.8s;
    /* Safari and Chrome */
    -webkit-animation: ${(props) => (props.$isModalOpen ? fadeIn : fadeOut)}
      0.8s;
    -o-animation: ${(props) => (props.$isModalOpen ? fadeIn : fadeOut)} 0.8s;
  }
`;
