import useLogger from "core/hooks/useLogger";
import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { io, Socket } from "socket.io-client";
import { COMMANDS } from "constants/enum/commands";
import { EVENTS } from "constants/enum/events";
import { STEP } from "constants/enum/step";
import { stepState } from "core/store/stepState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SCENARIO_STEP } from "constants/enum/scenarioStep";
import { useTheme } from "./ThemeContext";
import { tvEnvState } from "core/store/tvEnvState";

interface ISocketContext {
  socket: Socket;
  isConnected: boolean;
  volumeUp: () => void;
  volumeDown: () => void;
  sizeSimulatorStart: () => void;
  sizeSimulatorEnd: () => void;
  sizeSimulatorUserSize: (userSize: string) => void;
  sizeSimulatorScreenSize: (screenSize: string) => void;
  tvLineUp: () => void;
  tvLineEnd: () => void;
  scenarioStep: (step: STEP) => void;
  scenarioSkip: (step: STEP) => void;
  scenarioBridge: (step: STEP) => void;
  scenarioExperience: (step: STEP, isSkip?: boolean) => void;
  experienceData: (data: string) => void;
  scenarioEnd: () => void;
  startAgain: () => void;
  smartthingsStartAgain: () => void;
  smartThings: (step: STEP) => void;
  moreFeature: (index: number) => void;
  selectVideo: (name: string) => void;
}

const SocketContext = createContext<any>({});

export const SocketProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const { debug } = useLogger("SOCKET PROVIDER");
  const { SOCKET_URL } = useRecoilValue(tvEnvState);
  const { theme } = useTheme();
  const setStepState = useSetRecoilState(stepState);

  const socket = useMemo(
    () =>
      io(`${SOCKET_URL}`, {
        reconnection: true,
        autoConnect: false,
        transports: ["websocket"],
      }),
    [SOCKET_URL]
  );

  const [isConnected, setIsConnected] = useState<boolean>(false);

  const sizeSimulatorStart = () => {
    socket.emit(EVENTS.SIZE_SIMULATOR, COMMANDS.SIZE_SIMULATOR_START);
  };

  const sizeSimulatorEnd = () => {
    socket.emit(EVENTS.SIZE_SIMULATOR_END, COMMANDS.SIZE_SIMULATOR_END);
  };

  const sizeSimulatorUserSize = (userSize: string) => {
    socket.emit(EVENTS.SIZE_SIMULATOR, { userSize });
  };

  const sizeSimulatorScreenSize = (screenSize: string) => {
    socket.emit(EVENTS.SIZE_SIMULATOR, { screenSize });
  };

  const volumeUp = () => {
    socket.emit(EVENTS.VOLUME, COMMANDS.VOLUME_UP);
  };

  const volumeDown = () => {
    socket.emit(EVENTS.VOLUME, COMMANDS.VOLUME_DOWN);
  };

  const tvLineUp = () => {
    socket.emit(EVENTS.LINE_UP);
  };

  const tvLineEnd = () => {
    socket.emit(EVENTS.LINE_UP_END);
  };

  const moreFeature = (index: number) => {
    socket.emit(EVENTS.MORE_FEATURE, index);
  };

  const smartThings = (step: STEP) => {
    const socketData = { command: COMMANDS.SCENARIO_EXPERIENCE, step };
    socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, socketData);
  };

  const scenarioStep = (step: STEP) => {
    const socketData = { command: COMMANDS.SCENARIO_START, step };
    socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, socketData);
  };

  const scenarioSkip = (step: STEP) => {
    /// step이 있으면 눌러서 넘어온 것
    const socketData = { command: COMMANDS.SCENARIO_SKIP, step };
    socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, socketData);
  };

  const scenarioBridge = (step: STEP) => {
    const socketData = { command: COMMANDS.SCENARIO_BRIDGE, step };
    socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, socketData);
  };

  const scenarioExperience = (step: STEP, isSkip = false) => {
    /// step이 있으면 SNB에서 한번에 넘어옴
    const socketData = { command: COMMANDS.SCENARIO_EXPERIENCE, step, isSkip };
    socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, socketData);
  };

  const experienceData = (data: string) => {
    const socketData = { command: COMMANDS.EXPERIENCE_DATA, data };
    socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, socketData);
  };

  const scenarioEnd = () => {
    socket.emit(EVENTS.SCENARIO_END);
  };

  const selectVideo = (index: number) => {
    socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, {
      command: COMMANDS.EXPERIENCE_DATA,
      index: index,
    });
  };

  const startAgain = () => {
    scenarioStep(theme.StepList[1]);
    setStepState({
      step: theme.StepList[1],
      scenarioStep: SCENARIO_STEP.START,
    });
  };

  const smartthingsStartAgain = () => {
    scenarioStep(theme.StepList[1]);
    setStepState({
      step: theme.StepList[1],
      scenarioStep: SCENARIO_STEP.EXPERIENCE,
    });
  };

  useEffect(() => {
    socket.on("connect", () => {
      debug("socket connected!!");
      setIsConnected(true);
    });
    socket.on("disconnect", () => {
      debug("socket disconnected!!");
      setIsConnected(false);
    });
    socket.on("connect_error", (err) => {
      debug(`connect_error due to ${err.message}`);
    });
  }, [socket, debug]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        isConnected,
        volumeUp,
        volumeDown,
        sizeSimulatorStart,
        sizeSimulatorEnd,
        sizeSimulatorUserSize,
        sizeSimulatorScreenSize,
        tvLineUp,
        tvLineEnd,
        scenarioStep,
        scenarioExperience,
        scenarioSkip,
        scenarioBridge,
        experienceData,
        scenarioEnd,
        smartThings,
        moreFeature,
        startAgain,
        smartthingsStartAgain,
        selectVideo,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): ISocketContext => {
  const ctx = useContext(SocketContext);
  if (ctx === undefined) {
    throw new Error("useSocket can only be used inside SocketContext");
  }
  return ctx;
};
