import { palette } from "constants/theme";
import { typoOne700C } from "constants/theme/fonts";
import { TitleContainer } from "constants/theme/globalStyles";
import styled, { keyframes } from "styled-components";

const zoomIn = keyframes`
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
    transform: scale(1.1);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
`;

export const ValueBox = styled.div`
  ${typoOne700C}
  font-size: 12px;
`;

export const MainBar = styled.div`
  width: 0%;
  height: 6px;
  transition: width 0.1s linear;
  position: absolute;
  top: 0;

  border-radius: 3px;
  background-color: ${(props) => props.theme.mainColor};
`;

export const IconContainer = styled.div`
  animation: ${zoomIn} 0.5s;
`;

export const BackgroundBar = styled.div`
  width: 100%;
  height: 4px;

  position: absolute;
  top: 1px;

  border-radius: 2px;
  background-color: ${palette.grayScale.dark_40};
`;

export const BarContainer = styled.div`
  position: relative;

  width: 75%;
  height: 6px;
`;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 100%;
  height: calc(100% - (100% - 60px));
`;

export const StartContent = styled.div<{ $isSkip: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 100%;
  height: calc(100% - 60px);

  > button {
    position: absolute;
    bottom: 0;
    padding-inline: ${(props) => (props.$isSkip ? "45px" : "10px")};
  }
`;

export const SoundMiddle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  top: -3vh;
`;

export const StartContainer = styled.div`
  position: relative;

  width: 100vw;
  height: 100%;
  /* padding-top: 15vh; */

  > svg {
    width: auto;
    height: 119px;
  }

  > ${TitleContainer} {
    margin-bottom: 2vh;
  }
`;
