import { palette } from "constants/theme";
import { typoOneBold, typoSharpSansBold, typoSharpSansRegular } from "constants/theme/fonts";
import styled from "styled-components";

export const ExplainRegular = styled.div`
  ${typoSharpSansRegular}

  font-size: 1rem;
  text-align: center;
  line-height: 22px;
`;

export const ExplainBold = styled.div`
  ${typoSharpSansRegular}

  font-size: 1rem;
  line-height: 22px;
  ${typoSharpSansBold}
`;

export const RegisterFormContainer = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  width: calc(100% + 1px);
`;

export const RegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;

  margin-top: 24px;
  padding: 24px 24px 16px 24px;
  width: 342px;

  border-radius: 4px;
  background: #262626;
`;

export const RegisterSubsidiary = styled.div`
  ${typoSharpSansRegular}

  display: grid;
  grid-template-columns: 110px 214px;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 100%;
`;

export const Submit = styled.button`
  ${typoOneBold}

  padding: 8px 22px;
  text-align: center;
  border-radius: 4px;
  border: none;
  font-size: 20px;
  background: #3797ed;
  color: ${palette.grayScale.dark_0};

  cursor: pointer;
`;

export const SuccessContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const SuccessExplainRegular = styled.div`
  ${typoSharpSansRegular}

  font-size: 17px;
  text-align: center;
  line-height: 27px;
`;
