import { memo, useEffect, useState } from "react";
import SetMyTv from "./SetMyTv";
import ChooseTv from "./ChooseTv";
import { DefaultModalProps } from "types/common";
import { useFunnel } from "core/hooks/useFunnel";
import { EVENTS } from "constants/enum/events";
import Modal from "layout/Modal/Modal";
import { useSocket } from "core/contexts/SocketContext";

const SizeSimulatorModal = ({ isModalOpen, modalCloseHandler }: DefaultModalProps) => {
  const [Funnel, setStep] = useFunnel(["setMyTv", "chooseTv"] as const, "setMyTv");
  const { socket } = useSocket();
  const [tvSize, setTvSize] = useState(98);

  useEffect(() => {
    const sizeSimulatorSocket = (data: any) => {
      setTvSize(() => data.screenSize);
    };

    if (!socket.hasListeners(EVENTS.SIZE_SIMULATOR)) {
      socket.on(EVENTS.SIZE_SIMULATOR, sizeSimulatorSocket);
    }

    return () => {
      socket.off(EVENTS.SIZE_SIMULATOR, sizeSimulatorSocket);
    };
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      setStep("setMyTv");
    }
  }, [isModalOpen]);

  return isModalOpen !== null ? (
    <Modal isModalOpen={isModalOpen} modalCloseHandler={modalCloseHandler}>
      <Funnel>
        <Funnel.Step name="setMyTv">
          <SetMyTv setStep={setStep} />
        </Funnel.Step>
        <Funnel.Step name="chooseTv">
          <ChooseTv tvSize={tvSize} />
        </Funnel.Step>
      </Funnel>
    </Modal>
  ) : null;
};

export default memo(SizeSimulatorModal);
