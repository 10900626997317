import { useMemo } from "react";
import { useLocation } from "react-router-dom";

type ValueOf<T extends readonly any[]> = T[number];
type GetQueryType<T extends readonly any[]> = {
  [K in ValueOf<T>]: string;
};

export function useUrlQuery<T extends readonly string[]>(
  names: T
): GetQueryType<T> {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const object = {} as GetQueryType<T>;

  names.forEach((name) => {
    (object as any)[name] = query.get(name) ?? "";
  });

  return object;
}
