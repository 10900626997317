import { memo, useEffect, useState } from "react";
import { Dim, ModalBody, ModalCloseButton, ModalContainer, ModalContent } from "./index.style";

import { ModalProps } from "types/common";
import { CloseModalIcon } from "constants/icons";
import { palette } from "constants/theme";
import useTimeout from "core/hooks/useTimer";

const Modal = ({
  children,
  modalCloseHandler,
  isModalOpen,
  isCloseBtn = true,
  isOfflineQr = false,
  isExperience = false,
}: ModalProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isFadeOut, setIsFadeOut] = useState<boolean>(false);
  const { setAsync } = useTimeout();

  const closeHandler = async () => {
    if (!modalCloseHandler) return;

    modalCloseHandler(false);
    setIsFadeOut(true);
    await setAsync(800);
    modalCloseHandler(null);
  };

  useEffect(() => {
    let timeOutId: any;

    if (isModalOpen) {
      setOpen(true);
    } else {
      timeOutId = setTimeout(() => setOpen(true), 150);
    }

    return () => {
      if (timeOutId !== undefined) {
        clearTimeout(timeOutId);
      }
    };
  }, [isModalOpen]);

  if (!open) {
    return null;
  }

  return (
    <ModalContainer
      $isExperience={isExperience}
      $isFadeOut={isFadeOut}
      $isModalOpen={isModalOpen !== null && isModalOpen}
    >
      {!isExperience && !isOfflineQr && isModalOpen && <Dim />}
      <ModalBody>
        {isCloseBtn && (
          <ModalCloseButton onClick={closeHandler}>
            <CloseModalIcon color={palette.grayScale.dark_0} />
          </ModalCloseButton>
        )}
        <ModalContent>{children}</ModalContent>
      </ModalBody>
    </ModalContainer>
  );
};

export default memo(Modal);
