import { useEffect, useState } from "react";

import { useSocket } from "core/contexts/SocketContext";
import { useTheme } from "core/contexts/ThemeContext";
import { useFunnel } from "core/hooks/useFunnel";

import StartLayout from "layout/StartLayout";
import CoverLayout from "layout/CoverLayout";

import { EVENTS } from "constants/enum/events";
import { scenarioTheme } from "constants/scenarioTheme";
import { Container } from "constants/theme/globalStyles";
import { COMMANDS } from "constants/enum/commands";
import CompareModal from "layout/Modal/CompareModal";
import { STEP } from "constants/enum/step";
import { useRecoilState } from "recoil";
import { stepState } from "core/store/stepState";
import useTimeout from "core/hooks/useTimer";
import { SCENARIO_STEP } from "constants/enum/scenarioStep";
import CongratulationLayout from "layout/CongratulationLayout";
import CongratulationCoach from "layout/CoachLayout/CongratulationCoach";
import { useOutletContext } from "react-router-dom";
import { OutletProps } from "types/theme";
import { useUrlQuery } from "core/hooks/useUrlQuery";
import { postMobileStartExp } from "core/util/api/tvApi";

const Oled = () => {
  const [step, setStepState] = useRecoilState(stepState);

  const [playTime, setPlayTime] = useState<number>(-1);

  const { theme, setTheme } = useTheme();
  const { clear, setAsync } = useTimeout();
  const { socket, isConnected, scenarioStep, scenarioExperience } = useSocket();
  const [Funnel, setStep] = useFunnel([...theme.StepList] as const, STEP.COVER);
  const [ExperienceFunnel, setExperienceStep] = useFunnel([...theme.ExperienceStepList] as const, SCENARIO_STEP.START);
  const query = useUrlQuery(["duid"] as const);

  const outletProps = useOutletContext<OutletProps>();

  const coachOpen = () => {
    outletProps?.setIsCoach(true);
  };

  const startEndTimerHandler = (step: STEP) => {
    setPlayTime(-1);
    scenarioExperience(step);
    setStepState({ step, scenarioStep: SCENARIO_STEP.EXPERIENCE });
  };

  const nextExperience = (step: STEP) => {
    scenarioStep(step);
    setStepState({ step, scenarioStep: SCENARIO_STEP.START });
  };

  /// 스텝으로 이동  ------------------------------------------------------------
  useEffect(() => {
    clear();
    setPlayTime(-1);
    setStep(step.step as any);
    setExperienceStep(step.scenarioStep as any);

    if (step.step === STEP.OUTRO || step.scenarioStep === SCENARIO_STEP.EXPERIENCE) {
      coachOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    const socketOn = async (data: any) => {
      switch (data.command) {
        case COMMANDS.SCENARIO_START:
          setPlayTime(parseInt(`${data.duration / 1000}`));
          break;
        default:
          break;
      }
    };

    if (!socket.hasListeners(EVENTS.SCENARIO_TV_TO_MOBILE)) {
      socket.on(EVENTS.SCENARIO_TV_TO_MOBILE, socketOn);
    }

    return () => {
      socket.off(EVENTS.SCENARIO_TV_TO_MOBILE, socketOn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  useEffect(() => {
    setTheme(scenarioTheme.oled);
    return () => {
      setStepState({ step: STEP.COVER, scenarioStep: SCENARIO_STEP.START });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Funnel>
        {theme.StepList.map((step, index) => {
          const layoutInfo = (theme.StartLayouts as any)[step];
          return (
            <Funnel.Step name={step} key={index}>
              {step === STEP.COVER ? (
                <CoverLayout
                  startHandler={() => {
                    postMobileStartExp(query.duid,"oled");
                    nextExperience(STEP.BLACKDETAIL);
                    outletProps?.commonHandler(true);
                  }}
                />
              ) : step === STEP.OUTRO ? (
                <>
                  {outletProps?.isCoach && (
                    <CongratulationCoach
                      coachHandler={() => {
                        outletProps?.setIsCoach(false);
                        outletProps?.commonHandler(true);
                      }}
                      experience="oled"
                    />
                  )}
                  <CongratulationLayout experience="oled" />
                </>
              ) : (
                <ExperienceFunnel>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.START}>
                    <StartLayout
                      layoutInfo={layoutInfo}
                      isSkip={true}
                      time={playTime}
                      buttonHandler={() => {
                        startEndTimerHandler(step);
                      }}
                      endTimerHandler={() => {
                        startEndTimerHandler(step);
                      }}
                    />
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.SKIP}>
                    <StartLayout
                      layoutInfo={layoutInfo}
                      isSkip={false}
                      isExperience={true} //playTime <= 0
                    />
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.EXPERIENCE}>
                    <CompareModal
                      isModalOpen={true}
                      modalCloseHandler={async () => {
                        await setAsync(800);
                        nextExperience(theme.StepList[index + 1]);
                      }}
                      startLayouts={layoutInfo}
                    />
                  </ExperienceFunnel.Step>
                </ExperienceFunnel>
              )}
            </Funnel.Step>
          );
        })}
      </Funnel>
    </Container>
  );
};

export default Oled;
