import { palette } from "constants/theme";
import { typoSharpSansBold } from "constants/theme/fonts";
import { TitleContainer } from "constants/theme/globalStyles";
import styled from "styled-components";

export const SmartThingsBox = styled.div<{ $select: boolean }>`
  position: relative;
  background-color: white;
  border: 3px solid ${({ $select }) => ($select ? palette.smartthings.skyblue : palette.grayScale.dark_35)};
  opacity: ${({ $select }) => ($select ? 1 : 0.7)};
  border-radius: 24px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    position: absolute;
  }

  > div {
    ${typoSharpSansBold}
    font-size: 20px;
    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    line-height: 30px;
    z-index: 1;
  }
`;

export const StartContent = styled.div<{ $isSkip: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 100%;
  height: calc(100% - 60px);

  > button {
    position: absolute;
    bottom: 0;
    padding-inline: ${(props) => (props.$isSkip ? "45px" : "10px")};
  }
`;

export const SmartThingsWrapper = styled.div`
  display: grid;
  grid-template-rows: 168px 168px;
  grid-template-columns: 154px 154px;
  gap: 24px;
`;

export const SmartThingsContainer = styled.div`
  position: relative;

  width: 100vw;
  height: 100%;
  /* padding-top: 15vh; */

  > svg {
    width: auto;
    height: 119px;
  }

  > ${TitleContainer} {
    margin-bottom: 2vh;
  }
`;
