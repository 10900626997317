import styled from "styled-components";

export const CompareContainer = styled.div<{ $isExplain: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => (props.$isExplain ? "70px" : "87px")};

  position: relative;
  top: calc(-30% + 150px);
`;
