import { palette } from "constants/theme";
import { typoOne400C, typoSharpSansBold } from "constants/theme/fonts";
import styled from "styled-components";

export const TitleContainer = styled.div<{ $isPrimary: boolean }>`
  width: 115px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  gap: 7px;

  position: absolute;
  top: 14px;
  left: 18px;

  color: ${(props) => (props.$isPrimary ? palette.secondary.navy_15 : palette.grayScale.dark_0)};

  > div:nth-of-type(1) {
    ${typoOne400C}
    font-size: 8px;
    line-height: 163%;
  }

  > div:nth-of-type(2) {
    ${typoSharpSansBold}
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.2px;
  }
`;

export const MarkFrame = styled.div<{ $isPrimary: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 11px;
  right: 12px;

  width: 67px;
  height: 67px;

  border: solid 1px ${palette.grayScale.dark_30};
  border-radius: 50px;

  box-shadow: ${({ theme, $isPrimary }) =>
    $isPrimary
      ? `
    8px 8px 10px ${theme.moreFeature.select.iconBoxShadow.rightDown}`
      : `
    8px 8px 10px ${theme.moreFeature.default.iconBoxShadow.rightDown}`};
  transition: all 0.9s ease;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 63px;
    height: 63px;

    border: solid 1px ${palette.grayScale.dark_30};
    border-radius: 50px;
    background: ${({ theme, $isPrimary }) =>
      $isPrimary
        ? `linear-gradient(312deg, ${theme.moreFeature.select.iconBgLinear.rightDown} 13.81%,
        ${theme.moreFeature.select.iconBgLinear.leftTop} 84.14%);`
        : `linear-gradient(312deg, ${theme.moreFeature.default.iconBgLinear.rightDown} 13.81%,
        ${theme.moreFeature.default.iconBgLinear.leftTop} 84.14%);`};
    transition: all 0.9s ease;
    color: ${(props) => (props.$isPrimary ? palette.grayScale.dark_0 : palette.secondary.navy_15)};
  }
`;

export const MoreFeatureBox = styled.div<{ $isPrimary: boolean }>`
  width: 148px;
  height: 162px;

  margin-right: 11px;

  position: relative;

  background: ${({ theme, $isPrimary }) =>
    $isPrimary
      ? `linear-gradient(318deg, ${theme.moreFeature.select.bgLinear.rightDown} 14.08%,
      ${theme.moreFeature.select.bgLinear.leftTop} 84.13%);`
      : `linear-gradient(318deg, ${theme.moreFeature.default.bgLinear.rightDown} 14.08%,
      ${theme.moreFeature.default.bgLinear.leftTop} 84.13%);`};
  transition: all 0.9s ease;
  border: solid 1px ${palette.grayScale.dark_30};
  border-radius: 25px;
`;

export const MoreFeatureContainer = styled.div`
  height: 164px;
`;
