import { atom } from "recoil";
import { linkAtomProps } from "types/link";

export const DEFAULT_PDP = "https://www.samsung.com/sec/tvs/";
export const DEFAULT_LINK = "https://www.samsung.com/sec/tvs/";

export const linkState = atom<linkAtomProps>({
  key: "linkState",
  default: {
    pdp: DEFAULT_PDP,
    smartthings:DEFAULT_LINK,
    magazine: DEFAULT_LINK,
  },
});
