import { keyframes } from "styled-components";

export const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  } to {
    opacity: 0;
  }
`;

export const fadeInUpToDown = keyframes`
  from {
    top:-5px;
    opacity: 0;
  } to {
    top:0;
    opacity: 1;
  }
`;

export const fadeOutUpToDown = keyframes`
  from {
    top:0;
    opacity: 1;
  } to {
    top:-5px;
    opacity: 0;
  }
`;

export const fadeInDownToUp = keyframes`
  from {
    bottom:-5px;
    opacity: 0;
  } to {
    bottom:0;
    opacity: 1;
  }
`;

export const fadeOutDownToUp = keyframes`
  from {
    bottom:0;
    opacity: 1;
  } to {
    bottom:-5px;
    opacity: 0;
  }
`;

export const fadeOutModal = keyframes`
  from {
    bottom:0;
    opacity: 1;
  } to {
    bottom:-5px;
    opacity: 0;
  }
`;

export const fadeInSNB = keyframes`
  from {
    right:-5px;
    opacity: 0;
  } to {
    right:0;
    opacity: 1;
  }
`;

export const fadeOutSNB = keyframes`
  from {
    right:0;
    opacity: 1;
  } to {
    right:-5px;
    opacity: 0;
  }
`;
