import "constants/theme/fonts.css";
import GlobalStyle from "constants/theme/globalStyles";
import { LanguageProvider } from "core/contexts/LanguageContext";
import { SocketProvider } from "core/contexts/SocketContext";
import { ThemeProvider } from "core/contexts/ThemeContext";
import MRouter from "core/routes/Router";
import { tvEnvState } from "core/store/tvEnvState";
import { useRecoilValue } from "recoil";
import { initI18n } from "core/util/i18n";

initI18n();

function App() {
  const tvEnv = useRecoilValue(tvEnvState);

  return (
    <>
      <GlobalStyle $subsidiary={tvEnv.SUBSIDIARY} />
      <ThemeProvider>
        <SocketProvider>
          <LanguageProvider>
            <MRouter />
          </LanguageProvider>
        </SocketProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
