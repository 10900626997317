import { useRef } from "react";

export const useFullScreen = () => {
  const element = useRef<HTMLDivElement>(null);
  const triggerFull = async () => {
    if (element.current) {
      if (element.current.requestFullscreen)
        await element.current.requestFullscreen();
      else if ((element.current as any).mozRequestFullScreen)
        (element.current as any).mozRequestFullScreen();
      else if ((element.current as any).webkitRequestFullscreen)
        (element.current as any).webkitRequestFullScreen();
      else if ((element.current as any).msRequestFullscreen)
        (element.current as any).msRequestFullscreen();
    }
  };
  const exitFull = () => {
    document.exitFullscreen();
  };

  return { element, triggerFull, exitFull };
};
