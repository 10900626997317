import { memo, useEffect } from "react";
import {
  CoachAnimation,
  CoachContainer,
  OtsProArea,
  OtsProContainer,
  OtsProGuide,
} from "./Index.style";
import { Drag } from "constants/icons/coach";
import Pointed from "./Pointed";
import { css } from "styled-components";
import { CoachProps } from "types/common";
import { useTranslation } from "react-i18next";


const OtsProCoach = ({ coachHandler }: CoachProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      coachHandler();
    }, 3000);
  }, []);

  return (
    <CoachContainer onClick={coachHandler}>
      <OtsProContainer>
        <OtsProArea>
          <Drag />
          <OtsProGuide>{t("coach_ots_pro_guide")}</OtsProGuide>
          <Pointed
            positionCss={css`
              position: absolute;
              bottom: 0;
              left: calc(50vw + 20px);
              animation: ${CoachAnimation} 3s ease infinite;
            `}
          />
        </OtsProArea>
      </OtsProContainer>
    </CoachContainer>
  );
};

export default memo(OtsProCoach);
