import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "containers/Register";
import NotFound from "containers/NotFound";
import Picture from "containers/Picture";
import Sound from "containers/Sound";
import ExperienceLayout from "layout/ExperienceLayout";
import OfflineQr from "containers/OfflineQR/OfflineQr";
import Complete from "containers/Complete";

import SmartThings from "containers/SmartThings";
import Oled from "containers/Oled";
import OfflineQrEntry from "containers/OfflineQR/OfflineQrEntry";

const MRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ExperienceLayout />}>
          <Route path="/oled">
            <Route index element={<Oled />} />
            <Route path="*" element={<NotFound title={"Oled"} />} />
          </Route>
          <Route path="/sound">
            <Route index element={<Sound />} />
            <Route path="*" element={<NotFound title={"sound"} />} />
          </Route>
          <Route path="/picture4k">
            <Route index element={<Picture is8k={false} />} />
            <Route path="*" element={<NotFound title={"picture 4k"} />} />
          </Route>
          <Route path="/picture8k">
            <Route index element={<Picture is8k={true} />} />
            <Route path="*" element={<NotFound title={"picture 8k"} />} />
          </Route>
          <Route path="/smartThings">
            <Route index element={<SmartThings />} />
            <Route path="*" element={<NotFound title={"SmartThings"} />} />
          </Route>
        </Route>
        <Route path="/register">
          <Route index element={<Register />} />
          <Route path="*" element={<NotFound title={"register"} />} />
        </Route>
        <Route path="/offlineQrEntry">
          <Route index element={<OfflineQrEntry />} />
          <Route path="*" element={<NotFound title={"offlineQrEntry"} />} />
        </Route>
        <Route path="/offlineQr">
          <Route index element={<OfflineQr />} />
          <Route path="*" element={<NotFound title={"offlineQr"} />} />
        </Route>
        <Route path="/complete">
          <Route index element={<Complete />} />
          <Route path="*" element={<NotFound title={"Complete"} />} />
        </Route>
        {/* 빈 페이지 */}
        <Route path="*" element={<NotFound title={"root"} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MRouter;
