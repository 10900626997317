import { memo, useEffect, useState } from "react";
import {
  Submit,
  RegisterForm,
  ExplainRegular,
  ExplainBold,
  RegisterFormContainer,
  SuccessContainer,
  SuccessExplainRegular,
} from "./index.style";
import { RegisterOptionProps, RegisterProps } from "types/register";
import { useUrlQuery } from "core/hooks/useUrlQuery";
import { tvRegisterApi } from "core/util/api/tvApi";
import { CreateTvDto, SubsidiaryCodeEnum } from "types/api/api";
import RegisterLayout from "layout/RegisterLayout";
import TextField from "@mui/material/TextField";
import { CheckCircleIcon } from "constants/icons";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { tvEnvState } from "core/store/tvEnvState";

const defaultOptions: RegisterOptionProps = {
  SubsidiaryCode: {
    label: "Subsidiary",
    required: false,
  },
  retailer: {
    label: "Retailer",
    required: true,
  },
  storeName: {
    label: "Store",
    required: true,
  },
  tvName: {
    label: "TV Name",
    required: true,
  },
  pdp: {
    label: "Product Detail Page",
    required: false,
  },
};

const Register = () => {
  const query = useUrlQuery(["duid", "retailer", "subsidiary", "deviceModel", "scenario"] as const);
  const [focusKey, setFocusKey] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState<RegisterProps>({
    retailer: "",
    storeName: "",
    tvName: "",
    pdp: "",
  });
  const tvEnv = useRecoilValue(tvEnvState);
  const { t } = useTranslation();

  const onSubmit = async () => {
    const bodyData: CreateTvDto = {
      duid: query.duid,
      SubsidiaryCode: query.subsidiary as SubsidiaryCodeEnum,
      deviceModel: query.deviceModel,
      scenario: query.scenario,
      retailer: formData.retailer.trimEnd(),
      storeName: formData.storeName.trimEnd(),
      tvName: formData.tvName.trimEnd(),
      pdp: formData.pdp,
    };

    const data = await tvRegisterApi<any>(bodyData, tvEnv.SOCKET_URL);

    if (data) setIsSuccess(true);
  };

  useEffect(() => {
    setFormData({
      retailer: tvEnv.RETAILER ?? "",
      storeName: tvEnv.STORE ?? "",
      tvName: tvEnv.TVNAME ?? "",
      pdp: tvEnv.PDP ?? "",
    });
  }, [tvEnv]);

  return (
    <RegisterLayout>
      {!isSuccess ? (
        <>
          {/* Explain */}
          <div>
            <ExplainRegular>{t("register_guide_1")}</ExplainRegular>
            <ExplainBold>{t("register_guide_2")}</ExplainBold>
            <ExplainRegular>{t("register_guide_3")}</ExplainRegular>
          </div>
          {/* Form */}
          <RegisterFormContainer
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <RegisterForm>
              <TextField
                disabled
                fullWidth
                id="filled-basic"
                label="Subsidiary"
                defaultValue={`${query.subsidiary}`}
                sx={{
                  backgroundColor: "#bfbfbf",
                  borderRadius: "4px 4px 0 0",
                  "& input.Mui-disabled": {
                    "-webkit-text-fill-color": "#000",
                  },
                }}
                variant="filled"
              />

              {Object.keys(formData).map((key: string, idx) => {
                return (
                  <TextField
                    label={`${defaultOptions[key].label}${
                      focusKey !== key && formData[key].length <= 0 && defaultOptions[key].required ? ` (Required)` : ``
                    }`}
                    fullWidth
                    id="filled-basic"
                    variant="filled"
                    required={defaultOptions[key].required}
                    sx={{
                      ".MuiFormLabel-asterisk": {
                        color: "red",
                      },
                      backgroundColor: "#FFF",
                      borderRadius: "4px 4px 0 0",
                      "& input.Mui-disabled": {
                        "-webkit-text-fill-color": "#000",
                      },
                    }}
                    onBlur={(e) => setFocusKey("")}
                    onFocus={(e) => setFocusKey(key)}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        [key]: key === "pdp" ? e.target.value.replaceAll(" ", "") : e.target.value.trimStart(),
                      })
                    }
                    value={formData[key]}
                    key={idx}
                  ></TextField>
                );
              })}
            </RegisterForm>
            {/* Button */}
            <Submit type="submit">REGISTER</Submit>
          </RegisterFormContainer>
        </>
      ) : (
        <SuccessContainer>
          <CheckCircleIcon color="#00B151" />
          <SuccessExplainRegular>{t("register_success")}</SuccessExplainRegular>
        </SuccessContainer>
      )}
    </RegisterLayout>
  );
};

export default memo(Register);
