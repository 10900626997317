import { useCallback, useEffect, useRef } from "react";

export default function useTimeout() {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  const set = useCallback(
    (delay: number, callback?: (...args: any[]) => void) => {
      clear();
      timeoutRef.current = setTimeout(() => {
        callback && callback();
      }, delay);
    },
    [clear]
  );

  const setAsync = useCallback(
    (delay: number, callback?: (...args: any[]) => void): Promise<void> => {
      return new Promise((resolve) => {
        clear();
        timeoutRef.current = setTimeout(() => {
          callback && callback();
          resolve();
        }, delay);
      });
    },
    [clear]
  );

  useEffect(() => {
    return clear;
  }, [clear]);

  return { set, setAsync, clear };
}
