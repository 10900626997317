import { memo, useRef, useState } from "react";
import { MarkFrame, MoreFeatureContainer, MoreFeatureBox, TitleContainer } from "./index.style";
import { useTheme } from "core/contexts/ThemeContext";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import { useSocket } from "core/contexts/SocketContext";
import { useTranslation } from "react-i18next";

const MoreFeature = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { moreFeature } = useSocket();
  const [curIndex, setCurIndex] = useState<number | null>(null);
  const flickingRef = useRef<Flicking>(null);

  const moveToFlicking = (index: number) => {
    const flicking = flickingRef.current;
    if (!flicking) return;
    setCurIndex(index);
    flicking
      .moveTo(index)
      .then(() => moreFeature(index))
      .catch((e) => console.error(e.message));
  };

  return (
    <MoreFeatureContainer>
      <Flicking ref={flickingRef}>
        {theme.MoreFeatureList.map((elem, idx) => {
          const isPrimary = curIndex === idx;
          return (
            <MoreFeatureBox key={idx} $isPrimary={isPrimary} onClick={() => moveToFlicking(idx)}>
              <TitleContainer $isPrimary={isPrimary}>
                <div>{isPrimary ? t("common_now_playing") : t("morefeature_state_play_video")}</div>
                <div>{t(elem.title as string)}</div>
              </TitleContainer>
              <MarkFrame $isPrimary={isPrimary}>
                <div>{elem.icon}</div>
              </MarkFrame>
            </MoreFeatureBox>
          );
        })}
      </Flicking>
    </MoreFeatureContainer>
  );
};

export default memo(MoreFeature);
