import { memo } from "react";
import { HeaderButtonContainer, HeaderContainer, HeaderTitle } from "./index.style";
import VolumeBtn from "../VolumeBtn";
import MenuBtn from "../MenuBtn";
import SNB from "components/common/SNB";
import { useTheme } from "core/contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import { SNBProps } from "types/common";

const Header = ({ isSNBOpen, setIsSNBOpen }: SNBProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>{t(theme.headerTitle)}</HeaderTitle>
        <HeaderButtonContainer>
          <VolumeBtn />
          <MenuBtn isCoach={false} setIsSNBOpen={setIsSNBOpen} />
        </HeaderButtonContainer>
      </HeaderContainer>
      <SNB isSNBOpen={isSNBOpen} setIsSNBOpen={setIsSNBOpen} />
    </>
  );
};

export default memo(Header);
