import { useEffect, useState } from "react";

import { useSocket } from "core/contexts/SocketContext";
import { useTheme } from "core/contexts/ThemeContext";
import { useFunnel } from "core/hooks/useFunnel";

import StartLayout from "layout/StartLayout";
import CoverLayout from "layout/CoverLayout";

import { EVENTS } from "constants/enum/events";
import { scenarioTheme } from "constants/scenarioTheme";
import { Container } from "constants/theme/globalStyles";
import { COMMANDS } from "constants/enum/commands";
import CompareModal from "layout/Modal/CompareModal";
import { STEP } from "constants/enum/step";
import { useRecoilState } from "recoil";
import { stepState } from "core/store/stepState";
import useTimeout from "core/hooks/useTimer";
import { SCENARIO_STEP } from "constants/enum/scenarioStep";
import CongratulationLayout from "layout/CongratulationLayout";
import ExperienceCoach from "layout/CoachLayout/ExperienceCoach";
import CongratulationCoach from "layout/CoachLayout/CongratulationCoach";
import { useUrlQuery } from "core/hooks/useUrlQuery";
import { postMobileStartExp } from "core/util/api/tvApi";

interface IProps {
  is8k: boolean;
}

const Picture = ({ is8k }: IProps) => {
  const [step, setStepState] = useRecoilState(stepState);

  const [playTime, setPlayTime] = useState<number>(-1);

  const { theme, setTheme } = useTheme();
  const { clear, setAsync } = useTimeout();
  const { socket, isConnected, scenarioStep, scenarioSkip, scenarioBridge, scenarioExperience } = useSocket();
  const [Funnel, setStep] = useFunnel([...theme.StepList] as const, STEP.COVER);
  const [ExperienceFunnel, setExperienceStep] = useFunnel([...theme.ExperienceStepList] as const, SCENARIO_STEP.START);
  const [isCoach, setIsCoach] = useState<boolean>(false);

  const query = useUrlQuery(["duid"] as const);

  const coachOpen = () => {
    setIsCoach(true);
  };

  const coachClose = () => {
    setIsCoach(false);
  };

  const startSkipHandler = (step: STEP) => {
    setPlayTime(-1);
    setStepState({ step, scenarioStep: SCENARIO_STEP.SKIP });
    scenarioSkip(step);
  };

  const goBridge = (step: STEP) => {
    setStepState({ step, scenarioStep: SCENARIO_STEP.BRIDGE });
    scenarioBridge(step);
    setPlayTime(-1);
  };

  const nextExperience = (step: STEP) => {
    scenarioStep(step);
    setStepState({ step, scenarioStep: SCENARIO_STEP.START });
  };

  /// 스텝으로 이동  ------------------------------------------------------------
  useEffect(() => {
    clear();
    setPlayTime(-1);
    setStep(step.step as any);
    setExperienceStep(step.scenarioStep as any);

    if (step.step === STEP.OUTRO || step.scenarioStep === SCENARIO_STEP.SKIP) {
      coachOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    const socketOn = async (data: any) => {
      switch (data.command) {
        case COMMANDS.SCENARIO_START:
          setPlayTime(parseInt(`${data.duration / 1000}`));
          break;
        case COMMANDS.SCENARIO_BRIDGE:
          await setAsync(data.duration);
          setPlayTime(-1);
          setStepState((prev) => ({
            ...prev,
            scenarioStep: SCENARIO_STEP.EXPERIENCE,
          }));
          scenarioExperience(data.step);
          break;
        default:
          break;
      }
    };

    if (!socket.hasListeners(EVENTS.SCENARIO_TV_TO_MOBILE)) {
      socket.on(EVENTS.SCENARIO_TV_TO_MOBILE, socketOn);
    }

    return () => {
      socket.off(EVENTS.SCENARIO_TV_TO_MOBILE, socketOn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  useEffect(() => {
    setTheme(is8k ? scenarioTheme.picture8k : scenarioTheme.picture4k);
    return () => {
      setStepState({ step: STEP.COVER, scenarioStep: SCENARIO_STEP.START });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Funnel>
        {theme.StepList.map((step, index) => {
          const layoutInfo = (theme.StartLayouts as any)[step];
          return (
            <Funnel.Step name={step}>
              {step === STEP.COVER ? (
                <CoverLayout
                  startHandler={() => {
                    postMobileStartExp(query.duid,"picture");
                    nextExperience(theme.StepList[index + 1]);
                  }}
                />
              ) : step === STEP.OUTRO ? (
                <>
                  {isCoach && (
                    <CongratulationCoach
                      coachHandler={() => {
                        setIsCoach(false);
                      }}
                      experience="picture"
                    />
                  )}
                  <CongratulationLayout experience={is8k ? "picture8k" : "picture4k"} />
                </>
              ) : (
                <ExperienceFunnel>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.START}>
                    <StartLayout
                      layoutInfo={layoutInfo}
                      isSkip={true}
                      time={playTime}
                      buttonHandler={() => startSkipHandler(step)}
                      endTimerHandler={() => startSkipHandler(step)}
                    />
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.SKIP}>
                    {isCoach && <ExperienceCoach coachHandler={coachClose} />}
                    <StartLayout
                      layoutInfo={layoutInfo}
                      isSkip={false}
                      isExperience={playTime > 0}
                      time={playTime}
                      buttonHandler={() => goBridge(step)}
                    />
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.BRIDGE}>
                    <StartLayout layoutInfo={layoutInfo} isSkip={false} isExperience={true} />
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.EXPERIENCE}>
                    <CompareModal
                      isModalOpen={true}
                      modalCloseHandler={async () => {
                        await setAsync(800);
                        nextExperience(theme.StepList[index + 1]);
                      }}
                      startLayouts={layoutInfo}
                    />
                  </ExperienceFunnel.Step>
                </ExperienceFunnel>
              )}
            </Funnel.Step>
          );
        })}
      </Funnel>
    </Container>
  );
};

export default Picture;
