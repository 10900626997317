import { memo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ReplayContainer,
  SNBCloseButtonContainer,
  SNBContent,
  SNBContentElement,
  SNBTitleContainer,
  SNBButtonContainer,
  SNBbody,
  ReplayButton,
} from "./index.style";
import { SNBButtonProps, SNBProps, SNBStringProps, SetSNBType } from "types/common";

import { CloseIcon, LeftArrowIcon } from "constants/icons";
import { palette } from "constants/theme";
import { useTheme } from "core/contexts/ThemeContext";
import { stepState } from "core/store/stepState";
import { useSocket } from "core/contexts/SocketContext";
import { STEP } from "constants/enum/step";
import { noActiveState } from "core/store/activeState";
import { useTranslation } from "react-i18next";
import { tvEnvState } from "core/store/tvEnvState";

const SNB = ({ isSNBOpen, setIsSNBOpen }: SNBProps) => {
  const noActive = useRecoilValue(noActiveState);
  const { theme } = useTheme();
  const { t } = useTranslation();

  return isSNBOpen !== null && !noActive ? (
    <SNBbody $isSNBOpen={isSNBOpen}>
      <CloseBtn setIsSNBOpen={setIsSNBOpen} />
      <SNBContent>
        <SNBContentElement>
          <SNBTitle>{t("snb_title_explain")}</SNBTitle>
          <div>{t("snb_explain")}</div>
        </SNBContentElement>
        <SNBContentElement>
          <SNBTitle>{t("snb_title_list")}</SNBTitle>
          {theme.SNBList.map((elem, index) => (
            <SNBButton
              key={index}
              targetStep={elem.targetStep}
              setIsSNBOpen={setIsSNBOpen}
              isSmartThings={theme.headerTitle === "SmartThings"}
            >
              {t(elem.text)}
            </SNBButton>
          ))}
        </SNBContentElement>
      </SNBContent>
      <ReplayBtn setIsSNBOpen={setIsSNBOpen} />
    </SNBbody>
  ) : null;
};

export default memo(SNB);

// SUB COMPONENTS --------------------------------------

const SNBTitle = ({ children }: SNBStringProps) => {
  return (
    <SNBTitleContainer>
      <div>{children}</div>
      <hr />
    </SNBTitleContainer>
  );
};

const CloseBtn = ({ setIsSNBOpen }: SetSNBType) => {
  const { t } = useTranslation();

  return (
    <SNBCloseButtonContainer>
      <div>{t("common_close")}</div>
      <button
        onClick={() => {
          setIsSNBOpen(false);
        }}
      >
        <CloseIcon color={palette.grayScale.dark_0} />
      </button>
    </SNBCloseButtonContainer>
  );
};

const ReplayBtn = ({ setIsSNBOpen }: SetSNBType) => {
  const { theme } = useTheme();
  const { SCENARIO } = useRecoilValue(tvEnvState);
  const { startAgain, smartthingsStartAgain } = useSocket();
  const { t } = useTranslation();

  return (
    <ReplayContainer>
      <ReplayButton
        onClick={() => {
          setIsSNBOpen(false);
          SCENARIO === "smartthings" ? smartthingsStartAgain() : startAgain();
        }}
      >
        <LeftArrowIcon color={theme.color.icon.primary} />
        <div>{t("common_start_again")}</div>
      </ReplayButton>
    </ReplayContainer>
  );
};

const SNBButton = ({ children, targetStep, setIsSNBOpen }: SNBButtonProps) => {
  const { scenarioStep, scenarioExperience } = useSocket();
  const [step, setStepState] = useRecoilState(stepState);

  return (
    <SNBButtonContainer
      $isHere={step.step === targetStep.step}
      onClick={() => {

        setIsSNBOpen(false);
        setStepState(targetStep);
        if (targetStep.step === STEP.OUTRO) {
          step.step !== targetStep.step && scenarioStep(targetStep.step);
        } else {
          (step.step !== targetStep.step || step.scenarioStep !== "experience") &&
            scenarioExperience(targetStep.step, true);
        }
      }}
    >
      {children}
    </SNBButtonContainer>
  );
};
