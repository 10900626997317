import { typoSharpSansBold } from "constants/theme/fonts";
import styled from "styled-components";

export const StyleWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

export const Header = styled.div`
  ${typoSharpSansBold}

  display: flex;
  align-items: center;

  padding: 24px 30px 16px;

  font-size: 1.25rem;
  line-height: 34px;

  background-color: #2f2f2f;
`;

export const Body = styled.div`
  height: auto;
  min-height: calc(100% - 74px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: -42px 16px 32px 16px;
`;
