import { memo } from "react";
import {
  AgainButton,
  CongratulationContainer,
  DefaultContainer,
  MoreFeaturesContainer,
  MoreFeaturesTitle,
  OledContainer,
  TopContainer,
} from "./index.style";
import MoreFeature from "components/common/MoreFeature";
import { ExplainText, MainTitle, SubTitle } from "constants/theme/globalStyles";
import Button from "components/common/DefaultBtn";
import { OledCongratulationIcon, ReplayIcon } from "constants/icons";
import { CongratulationLayoutProps } from "types/common";
import { useSocket } from "core/contexts/SocketContext";
import { useRecoilValue } from "recoil";
import { linkState } from "core/store/linkState";
import { useTranslation } from "react-i18next";
import { setUrl } from "core/util/url";
import useHeight from "core/hooks/useHeight";
import { tvEnvState } from "core/store/tvEnvState";
import { postLinkAccess } from "core/util/api/tvApi";

const CongratulationLayout = ({ experience }: CongratulationLayoutProps) => {
  const { t } = useTranslation();
  const { isUnder } = useHeight();
  const { DUID } = useRecoilValue(tvEnvState);

  return (
    <>
      <CongratulationContainer>
        <TopContainer>
          {experience === "oled" ? <OledLayout DUID={DUID} /> : <DefaultLayout experience={experience} />}
        </TopContainer>
        <MoreFeaturesContainer $isUnder={isUnder}>
          <MoreFeaturesTitle $isUnder={isUnder}>{t("congratulation_morefeature_title")}</MoreFeaturesTitle>
          <MoreFeature />
        </MoreFeaturesContainer>
      </CongratulationContainer>
    </>
  );
};

export default memo(CongratulationLayout);

const DefaultLayout = ({ experience }: CongratulationLayoutProps) => {
  const { startAgain, smartthingsStartAgain } = useSocket();
  const { t } = useTranslation();
  return (
    <DefaultContainer>
      <MainTitle>{t("congratulation_default_title")}</MainTitle>
      <ExplainText>
        {t(`congratulation_default_${experience === "picture4k" ? "picture" : experience}_text`)}
      </ExplainText>
      <ExplainText>{t("congratulation_default_guide_play_again")}</ExplainText>
      <Button handler={experience === "smartthings" ? smartthingsStartAgain : startAgain}>
        {t("common_play_again")}
      </Button>
    </DefaultContainer>
  );
};

const OledLayout = ({ DUID }: { DUID: string }) => {
  const { startAgain } = useSocket();
  const { magazine } = useRecoilValue(linkState);
  const { t } = useTranslation();
  return (
    <OledContainer>
      <MainTitle>{t("congratulation_default_title")}</MainTitle>
      <AgainButton onClick={startAgain}>
        <div>{t("common_play_again")}</div>
        <ReplayIcon />
      </AgainButton>
      <OledCongratulationIcon />
      <SubTitle>{t("congratulation_oled_subtitle")}</SubTitle>
      <Button
        handler={() => {
          postLinkAccess(DUID, "magazinelink");
          window.open(setUrl(magazine), "_blank", "noreferrer");
        }}
      >
        {t("congratulation_oled_btn")}
      </Button>
    </OledContainer>
  );
};
