import { palette, zindex } from "constants/theme";
import styled, { keyframes } from "styled-components";

// -------------------------------  DEFAULT  ------------------------------------

export const DefalutBackgroundContainer = styled.div<{ $bgLinear: string }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zindex.background};

  width: 100vw;
  height: 100%;

  background-color: aliceblue;
  ${(props) => props.$bgLinear}

  > img {
    width: 100%;
    height: auto;
  }
`;

// -------------------------------  PICTURE  ------------------------------------

export const ShowAnimation = keyframes`
    0% {
        opacity: 0;
        transform: scale(.5)
    }

    50% {
        opacity: .8;
        transform: scale(2)
    }

    to {
        opacity: .5;
        transform: scale(1)
    }
`;

export const Container = styled.div<{ $bgLinear: any }>`
  position: absolute;
  top: -100px;
  z-index: ${zindex.background};

  width: 100vw;
  height: 100%;

  * > {
    animation-iteration-count: infinite;
  }

  .spotClass1 {
    fill: ${palette.grayScale.dark_0};
    opacity: 0;
    -webkit-animation: ${ShowAnimation} 3s linear 1;
    animation: ${ShowAnimation} 3s linear 1;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .spotClass2 {
    fill: ${palette.grayScale.dark_0};
    opacity: 0;
    -webkit-animation: ${ShowAnimation} 3s linear 1;
    animation: ${ShowAnimation} 3s linear 1;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .spotClass3 {
    fill: ${palette.grayScale.dark_0};
    opacity: 0;
    -webkit-animation: ${ShowAnimation} 3s linear 1;
    animation: ${ShowAnimation} 3s linear 1;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .spotClass4 {
    fill: ${palette.grayScale.dark_0};
    opacity: 0;
    -webkit-animation: ${ShowAnimation} 3s linear 1;
    animation: ${ShowAnimation} 3s linear 1;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }

  .spotClass5 {
    fill: ${palette.grayScale.dark_0};
    opacity: 0;
    -webkit-animation: ${ShowAnimation} 3s linear 1;
    animation: ${ShowAnimation} 3s linear 1;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .spotClass6 {
    fill: ${palette.grayScale.dark_0};
    opacity: 0;
    -webkit-animation: ${ShowAnimation} 3s linear 1;
    animation: ${ShowAnimation} 3s linear 1;
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }

  .spotClass7 {
    fill: ${palette.grayScale.dark_0};
    opacity: 0;
    -webkit-animation: ${ShowAnimation} 3s linear 1;
    animation: ${ShowAnimation} 3s linear 1;
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }

  .spotClass8 {
    fill: ${palette.grayScale.dark_0};
    opacity: 0;
    -webkit-animation: ${ShowAnimation} 3s linear 1;
    animation: ${ShowAnimation} 3s linear 1;
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .spotClass9 {
    fill: ${palette.grayScale.dark_0};
    opacity: 0;
    -webkit-animation: ${ShowAnimation} 3s linear 1;
    animation: ${ShowAnimation} 3s linear 1;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .spotClass10 {
    fill: ${palette.grayScale.dark_0};
    opacity: 0;
    -webkit-animation: ${ShowAnimation} 3s linear 1;
    animation: ${ShowAnimation} 3s linear 1;
    -webkit-animation-delay: 10s;
    animation-delay: 10s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
`;

// -------------------------------  SOUND  ------------------------------------
export const PulseKeyFrame = keyframes`
        0% {
        top: calc(100vh/2 - 25px);
        left: calc(50vw - 30px);
        z-index: ${zindex.background};

        width: 50px;
        height: 50px;
        border-radius: 25px;
        opacity: .9
    }

    80% {
        z-index: ${zindex.background};
        opacity: .9
    }

    to {
        top: calc(100vh/2 - 600px);
        left: calc(50vw - 500px);
        z-index: ${zindex.background};

        width: 1000px;
        height: 1000px;
        border-radius: 500px;
        opacity: 0
    }
`;

export const PulseContainer = styled.div<{ $bgLinear: string }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zindex.background};

  width: calc(100vw);
  height: 100%;

  overflow: hidden;
  ${(props) => props.$bgLinear}
`;

export const PulseBox = styled.div`
  position: absolute;
  left: -10px !important;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1000;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Pulse = styled.div`
  position: fixed;
  z-index: ${zindex.background};

  width: 50px;
  height: 50px;
  border-radius: 25px;

  &:after,
  &:before {
    position: fixed;
    top: calc(50vh - 500px);
    left: calc(50vw - 500px);
    z-index: ${zindex.background};

    width: 50px;
    height: 50px;

    border-radius: 25px;
    border: 6px solid #16173a;
    opacity: 0;
    content: "";
    -webkit-animation: ${PulseKeyFrame} 6s ease-out infinite;
    animation: ${PulseKeyFrame} 6s ease-out infinite;
  }

  &:before {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }

  &:after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
`;
