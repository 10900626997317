import MenuBtn from "components/common/MenuBtn";
import {
  ArrowBox,
  BlinkCss,
  CoachContainer,
  CongratulationBody,
  CongratulationBottom,
  CongratulationHeader,
  MoreFeatureAnimation,
  OledBottom,
  OledSubExplain,
} from "./Index.style";
import DefaultBtn from "components/common/DefaultBtn";
import { Arrow } from "constants/icons/coach";
import Pointed from "./Pointed";
import { css } from "styled-components";
import { AgainButton } from "../CongratulationLayout/index.style";
import { CongratulationCoachProps } from "types/common";
import { ReplayIcon } from "constants/icons";
import { useTranslation } from "react-i18next";

const CongratulationCoach = ({ coachHandler, experience }: CongratulationCoachProps) => {
  const { t } = useTranslation();

  return (
    <CoachContainer onClick={coachHandler}>
      <CongratulationHeader>
        <MenuBtn isCoach={true} />
      </CongratulationHeader>
      <CongratulationBody $isOled={experience === "oled"}>
        {experience === "oled" ? <OledCoach /> : <DefaultCoach />}
      </CongratulationBody>
      <CongratulationBottom>
        <div>{t("coach_congratulation_default_bottom")}</div>
        <ArrowBox>
          <Arrow />
          <Arrow />
        </ArrowBox>
        <Pointed
          positionCss={css`
            position: absolute;
            top: 22px;
            left: calc(50vw - 20px);
            animation: ${MoreFeatureAnimation} 3s ease infinite;
          `}
        />
      </CongratulationBottom>
    </CoachContainer>
  );
};

export default CongratulationCoach;

const DefaultCoach = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div>{t("coach_congratulation_default_guide")}</div>
      <DefaultBtn handler={() => {}}>{t("common_play_again")}</DefaultBtn>
    </div>
  );
};

const OledCoach = () => {
  const { t } = useTranslation();

  return (
    <div>
      <AgainButton onClick={() => {}}>
        <div>{t("common_play_again")}</div>
        <ReplayIcon />
      </AgainButton>
      <Pointed
        positionCss={css`
          position: absolute;
          top: 70px;
          right: calc(50vw - 10px);
          ${BlinkCss}
        `}
      />
      <OledSubExplain>{t("coach_congratulation_oled_guide_br")}</OledSubExplain>

      <OledBottom>
        <div>{t("coach_congratulation_oled_guide")}</div>
        <DefaultBtn handler={() => {}}>{t("coach_congratulation_oled_btn")}</DefaultBtn>
        <Pointed
          positionCss={css`
            position: absolute;
            top: 60px;
            right: calc(50vw - 100px);
            ${BlinkCss}
          `}
        />
      </OledBottom>
    </div>
  );
};
