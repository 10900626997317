import { useEffect } from "react";

import { useSocket } from "core/contexts/SocketContext";
import { useTheme } from "core/contexts/ThemeContext";
import { useFunnel } from "core/hooks/useFunnel";

import CoverLayout from "layout/CoverLayout";

import { scenarioTheme } from "constants/scenarioTheme";
import { Container } from "constants/theme/globalStyles";
import { STEP } from "constants/enum/step";
import { useRecoilState } from "recoil";
import { stepState } from "core/store/stepState";
import { SCENARIO_STEP } from "constants/enum/scenarioStep";
import CongratulationLayout from "layout/CongratulationLayout";
import { useOutletContext } from "react-router-dom";
import { OutletProps } from "types/theme";
import SmartThingsLayout from "layout/SmartThingsLayout";

const SmartThings = () => {
  const [step, setStepState] = useRecoilState(stepState);

  const { theme, setTheme } = useTheme();
  const { smartThings } = useSocket();
  const [Funnel, setStep] = useFunnel([...theme.StepList] as const, STEP.COVER);
  const [, setExperienceStep] = useFunnel([...theme.ExperienceStepList] as const, SCENARIO_STEP.EXPERIENCE);

  const outletProps = useOutletContext<OutletProps>();

  const nextExperience = (step: STEP) => {
    smartThings(step);
    setStepState({ step, scenarioStep: SCENARIO_STEP.EXPERIENCE });
  };

  /// 스텝으로 이동  ------------------------------------------------------------
  useEffect(() => {
    setStep(step.step as any);
    setExperienceStep(step.scenarioStep as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    setTheme(scenarioTheme.smartthings);
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Funnel>
        {theme.StepList.map((step) => {
          return (
            <Funnel.Step name={step} key={step}>
              {step === STEP.COVER && (
                <CoverLayout
                  startHandler={() => {
                    nextExperience(STEP.SMARTTHINGS);
                    outletProps?.commonHandler(true);
                  }}
                />
              )}
              {step === STEP.OUTRO && <CongratulationLayout experience="smartthings" />}
              {step === STEP.SMARTTHINGS && <SmartThingsLayout />}
            </Funnel.Step>
          );
        })}
      </Funnel>
    </Container>
  );
};

export default SmartThings;
