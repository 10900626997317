import { memo, useState } from "react";
import * as _ from "./index.style";
import Button from "components/common/DefaultBtn";
import bgPicture from "assets/welcome/bg_picture.png";
import bgOled from "assets/image/AI_Energy_Mode.png";
import bgSound from "assets/welcome/bg_sound.png";
import { useSetRecoilState } from "recoil";
import { stepState } from "core/store/stepState";
import { SCENARIO_STEP } from "constants/enum/scenarioStep";
import { STEP } from "constants/enum/step";
import { useSocket } from "core/contexts/SocketContext";
import { SMARTTHINGS_VIDEO } from "constants/enum/smartthings";
import { BoxList } from "./constant";
import { useTranslation } from "react-i18next";

let images: HTMLImageElement[] = [];

const preloading = (imageArray: string[]) => {
  imageArray.forEach((url, i) => {
    images[i] = new Image();
    images[i].src = url;
  });
};

preloading([bgPicture, bgOled, bgSound]);

const SmartThingsLayout = () => {
  const [select, setSelect] = useState<string>("Connect");
  const setStepState = useSetRecoilState(stepState);
  const { selectVideo, scenarioExperience } = useSocket();

  const { t } = useTranslation();

  return (
    <_.SmartThingsContainer>
      <_.StartContent $isSkip={true}>
        <_.SmartThingsWrapper>
          {BoxList.map((item, i) => (
            <_.SmartThingsBox
              $select={select === item.key}
              key={item.key}
              onClick={() => {
                selectVideo(SMARTTHINGS_VIDEO[item.key]);
                setSelect(item.key);
              }}
            >
              <div>{t(item.text)}</div>
              <img
                src={images[i === 3 ? 0 : i].src}
                alt=""
                width={item.key === "Audio" ? "250px" : "100%"}
                height={item.key === "Audio" ? "250px" : "100%"}
                style={item.style}
              />
            </_.SmartThingsBox>
          ))}
        </_.SmartThingsWrapper>
        <Button
          handler={() => {
            setStepState({ step: STEP.OUTRO, scenarioStep: SCENARIO_STEP.START });
            scenarioExperience(STEP.OUTRO);
          }}
        >
          {t("smartthings_next")}
        </Button>
      </_.StartContent>
    </_.SmartThingsContainer>
  );
};

export default memo(SmartThingsLayout);
