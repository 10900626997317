import { memo, useState } from "react";
import {
  InchesBox,
  InputCancelButton,
  KeyContainer,
  KeyPadContainer,
  TvInputContainer,
  TvNotice,
} from "./SetMyTv.style";
import DefaultBtn from "components/common/DefaultBtn";
import { ElementContainer } from "./index.style";
import { SetMyTvProps } from "types/common";
import { DeleteIcon, TvCheckCircleIcon } from "constants/icons";
import { palette } from "constants/theme";
import { useSocket } from "core/contexts/SocketContext";
import { useTranslation } from "react-i18next";

const SetMyTv = ({ setStep }: SetMyTvProps) => {
  const [inches, setInches] = useState<string>("input");
  const { sizeSimulatorUserSize } = useSocket();
  const { t } = useTranslation();
  const isCancelButton = inches !== "input";

  const cancelButtonHander = () => {
    setInches("input");
  };

  const keyHandler = (number: string) => {
    setInches(inches === "input" ? number : inches + number);
  };

  return (
    <ElementContainer>
      <TvCheckCircleIcon color={palette.grayScale.dark_70} />
      <TvInputContainer>
        <div>{t("size_simulator_set_my_tv_input_before")}</div>
        <InchesBox $isCancelButton={isCancelButton}>
          <div>{inches}</div>
          {isCancelButton && (
            <InputCancelButton onClick={cancelButtonHander}>
              <DeleteIcon />
            </InputCancelButton>
          )}
        </InchesBox>
        <div>{t("size_simulator_set_my_tv_input_unit")}</div>
      </TvInputContainer>
      <TvNotice>{t("size_simulator_set_my_tv_input_guide")}</TvNotice>
      <KeyPadContainer>
        <div>
          <KeyPad inches={inches} keyHandler={keyHandler} />
        </div>
      </KeyPadContainer>
      <DefaultBtn
        handler={() => {
          if (inches.length !== 2) return;
          setStep("chooseTv");
          sizeSimulatorUserSize(inches);
        }}
      >
        {t("set_ok")}
      </DefaultBtn>
    </ElementContainer>
  );
};

export default memo(SetMyTv);

interface KeyPadProps {
  inches: string;
  keyHandler: (number: string) => void;
}

const KeyPad = ({ inches, keyHandler }: KeyPadProps) => {
  let numbers: string[] = [];
  const isInputFirst = inches === "input";

  if (isInputFirst) {
    numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
  } else {
    numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
  }

  return (
    <>
      {numbers.map((elem, idx) => {
        let isKeyPrimary = false;

        if (inches.length === 2) {
          // pass
        } else if (isInputFirst && idx < 6 && idx >= 2) {
          isKeyPrimary = true;
        } else if (!isInputFirst) {
          if (inches[0] === "3" && ((idx < 1 && idx !== numbers.length - 1) || idx === numbers.length - 1)) {
            isKeyPrimary = false;
          } else if ((inches[0] === "6" && (idx < 5 || idx === numbers.length - 1)) || parseInt(inches[0]) < 6) {
            isKeyPrimary = true;
          }
        }

        return (
          <KeyContainer
            key={idx}
            onClick={() => {
              isKeyPrimary && keyHandler(elem);
            }}
            $isKeyPrimary={isKeyPrimary}
          >
            {elem}
          </KeyContainer>
        );
      })}
    </>
  );
};
