import { SMARTTHINGS_VIDEO_KEY_TYPE } from "constants/enum/smartthings";
import { CSSProperties } from "styled-components";

import bgPicture from "assets/welcome/bg_picture.png";
import bgOled from "assets/image/AI_Energy_Mode.png";
import bgSound from "assets/welcome/bg_sound.png";

type BoxListType = { key: SMARTTHINGS_VIDEO_KEY_TYPE; text: string; img: string; style?: CSSProperties }[];

export const BoxList: BoxListType = [
  {
    key: "Connect",
    text: "smartthings_item_1",
    img: bgPicture,
  },
  {
    key: "Audio",
    text: "smartthings_item_2",
    img: bgSound,
    style: { objectFit: "cover", top: "-20px", right: "-23px" },
  },
  {
    key: "Energy",
    text: "smartthings_item_3",
    img: bgOled,
  },
  {
    key: "Built",
    text: "smartthings_item_4",
    img: bgPicture,
  },
];
