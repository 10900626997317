import { typoSharpSansRegular } from "constants/theme/fonts";
import styled from "styled-components";

export const NoActiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 27px;

  text-align: center;
  ${typoSharpSansRegular}
  font-size: 17px;
  line-height: 140%;
`;
