import Modal from "layout/Modal/Modal";
import { memo } from "react";
import { DefaultModalProps } from "types/common";
import { LineupContent } from "./index.style";
import { TvCheckCircleIcon } from "constants/icons";
import { useTheme } from "core/contexts/ThemeContext";
import { useTranslation } from "react-i18next";

const TvLineupModal = ({ isModalOpen, modalCloseHandler }: DefaultModalProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  return isModalOpen !== null ? (
    <Modal isModalOpen={isModalOpen} modalCloseHandler={modalCloseHandler}>
      <LineupContent>
        <TvCheckCircleIcon color={theme.color.icon.primary} />
        {t("tv_line_up_guide")}
      </LineupContent>
    </Modal>
  ) : null;
};

export default memo(TvLineupModal);
