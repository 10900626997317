import { memo } from "react";
import { ButtonContainer } from "./index.style";
import { DefaultButtonProps } from "types/common";

const DefaultButton = ({ children, handler, isDefault = false, ...props }: DefaultButtonProps) => {
  return (
    <ButtonContainer $isDefault={isDefault} onClick={handler} {...props}>
      {children}
    </ButtonContainer>
  );
};

export default memo(DefaultButton);
