import { palette } from "constants/theme";
import { typoOne400C, typoSharpSansRegular } from "constants/theme/fonts";
import styled from "styled-components";

export const KeyContainer = styled.button<{ $isKeyPrimary: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20%;
  height: 55px;

  ${typoSharpSansRegular}
  font-size: 20px;

  background-color: transparent;
  border: none;
  color: ${palette.grayScale.dark_0};
  opacity: ${(props) => (props.$isKeyPrimary ? 1 : 0.5)};
`;

export const KeyPadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(100vw - 5vw * 2);
  padding: 10px 5vw;
  margin-block: 25px 36px;

  background-color: ${palette.grayScale.dark_80};

  > div {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    max-width: 325px;
  }
`;

export const InputCancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0 3px 3px 0;

  border-radius: 8px;
  border: none;

  overflow: hidden;

  background-color: transparent;

  > svg {
    width: inherit;
    height: inherit;

    color: ${(props) => props.theme.mainColor};

    > path {
      stroke: ${palette.grayScale.dark_45};
    }
  }
`;

export const InchesBox = styled.div<{ $isCancelButton: boolean }>`
  display: flex;
  justify-content: ${(props) =>
    props.$isCancelButton ? "space-between" : "center"};
  align-items: center;

  width: 90px;
  margin-inline: 3px;
  border-bottom: solid 2px ${palette.grayScale.dark_5};

  > div:nth-of-type(1) {
    position: relative;
    left: 3px;

    width: ${(props) => (props.$isCancelButton ? 90 : 100)}%;

    background-color: beige;
    color: ${(props) => props.theme.mainColor};
    font-size: 24px;
    line-height: 134%;
    ${typoSharpSansRegular}
    text-align: center;

    background-color: transparent;
    border: none;
  }
`;

export const TvNotice = styled.div`
  color: ${palette.grayScale.dark_25};
  font-size: 10px;
  line-height: 100%;
  ${typoOne400C}
`;

export const TvInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-block: 27px 9px;

  font-size: 24px;
  line-height: 134%;
  ${typoSharpSansRegular}
`;
