import { memo } from "react";
import { PointedProps } from "types/common";
import { OvalContainer, PointContainer } from "./Index.style";
import { OvalBig, OvalSmall } from "constants/icons/coach";
import FingerFilled from "assets/coach/Filled_finger.png";
import FingerBlank from "assets/coach/Blank_finger.png";
import { useTheme } from "core/contexts/ThemeContext";

const Pointed = ({
  positionCss,
  fingerfilled = false,
  fingerImage = true,
}: PointedProps) => {
  const { theme } = useTheme();

  return (
    <PointContainer $position={positionCss} $fingerfilled={fingerfilled}>
      <OvalContainer>
        <OvalBig color={theme.color.mainColor} />
        <OvalSmall color={theme.color.mainColor} />
      </OvalContainer>

      {fingerImage && (
        <img
          src={fingerfilled ? FingerFilled : FingerBlank}
          alt="Finger icon"
        />
      )}
    </PointContainer>
  );
};

export default memo(Pointed);
