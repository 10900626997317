import { WarningCircleIcon } from "constants/icons";
import { CannotEnterContainer } from "./index.style";
import { useTranslation } from "react-i18next";

const CannotEnter = () => {
  const { t } = useTranslation();
  return (
    <CannotEnterContainer>
      <WarningCircleIcon />
      <div>{t("offlineqr_cannot_enter")}</div>
    </CannotEnterContainer>
  );
};

export default CannotEnter;
