import { palette } from "constants/theme";
import { typoSharpSansMediumBold } from "constants/theme/fonts";
import { motion } from "framer-motion";
import styled from "styled-components";
export const CONTAINER_WIDTH = 270;
export const SLIDER_SIZE = 68;
export const DEFAULT_SLIDER_COLOR = "transparent";
export const DIVISION_POINT_SIZE = 40;

const MAIN_COLOR = "transparent";

export const SliderContainer = styled.div`
  width: ${CONTAINER_WIDTH}px;
  height: ${SLIDER_SIZE}px;
  position: relative;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  margin: 11px;
`;

export const SliderCursor = styled(motion.div)`
  background-color: ${DEFAULT_SLIDER_COLOR};
  box-sizing: border-box;
  width: ${SLIDER_SIZE}px;
  height: ${SLIDER_SIZE}px;
  border-radius: 50%;
  z-index: 1;
  cursor: grab;
`;

export const SliderBgBorder = styled.div`
  position: absolute;
  width: ${CONTAINER_WIDTH + 22}px;
  height: ${SLIDER_SIZE + 22}px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: ${(SLIDER_SIZE + 20) / 2}px;
  background-color: ${palette.grayScale.dark_0};
`;

export const DivisionsWrap = styled.div`
  position: absolute;
  width: ${CONTAINER_WIDTH - SLIDER_SIZE}px;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  align-items: center;
`;

export const DivLine = styled.div`
  position: absolute;
  top: 50%;
  width: ${SLIDER_SIZE}px;
  height: ${SLIDER_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivWrap = styled.div`
  position: absolute;
  top: 50%;
  width: ${SLIDER_SIZE}px;
  height: ${SLIDER_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Division = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: ${DIVISION_POINT_SIZE}px;
  height: ${DIVISION_POINT_SIZE}px;
  border-radius: 50%;
  border: 5px solid ${MAIN_COLOR};
  cursor: pointer;
  &:after {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    content: "";
    width: ${SLIDER_SIZE + 10}px;
    height: ${SLIDER_SIZE + 10}px;
  }
`;

export const Placeholder = styled.div`
  position: absolute;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const PlaceholderDot = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${palette.grayScale.dark_80};
  opacity: 0.08;
  border-radius: 100px;
`;

export const SliderLabelContainer = styled.div`
  display: flex;
  align-items: flex-start;

  position: absolute;
  left: -11px;
  bottom: -50px;
  width: ${CONTAINER_WIDTH + 22}px;
`;

export const SliderLabel = styled.div`
  flex: 1;

  ${typoSharpSansMediumBold}
  font-size: 13px;
  text-align: center;
`;
