import { zindex } from "constants/theme";
import { typoOne400C } from "constants/theme/fonts";
import styled, { RuleSet, css, keyframes } from "styled-components";

const SwipeAnimation = keyframes`
    0% {
      transform: translateY(0px);
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s;
      -webkit-transition: opacity 0.5s;
    }
    100% {
      transform: translateY(-100px);
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.5s, opacity 0.5s;
      -webkit-transition:  visibility 0s 0.5s, opacity 0.5s;
    }
`;

const BlickAnimation = keyframes`
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`;

export const MoreFeatureAnimation = keyframes`
    0% {
        left: calc(50vw - 20px);
    }
    33% {
        left: calc(50vw - 75px);
    }
    66% {
        left: calc(50vw + 30px);
    }
    100% {
        left: calc(50vw - 20px);
    }
`;

export const CoachAnimation = keyframes`
    0% {
      top: 167px;
      left: calc(50vw - 35px - 25px);
    }
    17% {
      top: 167px;
      left: calc(50vw + 35px - 25px);
    }
    34% {
      top: 247px;
      left: calc(50vw - 35px - 25px);
    }
    51% {
      top: 247px;
      left: calc(50vw + 35px - 25px);
    }
    68% {
      top: 247px;
      left: calc(50vw - 35px - 25px);
    }
    85% {
      top: 167px;
      left: calc(50vw + 35px - 25px);
    }
    100% {
      top: 167px;
      left: calc(50vw - 35px - 25px);
    }
`;

const fontStyle = css`
  ${typoOne400C}
  font-size: 16px;
  line-height: 137.5%;
  color: ${(props) => props.theme.mainColor};
`;

export const BlinkCss = css`
  animation: ${BlickAnimation} 1s ease infinite;
`;

export const SwipeContainer = styled.div`
  position: relative;
  top: 0;

  animation: ${SwipeAnimation} 1.5s ease infinite;
  -webkit-animation: ${SwipeAnimation} 1.5s ease infinite;

  > svg:nth-of-type(1) {
    width: 70px;
    height: 110px;
  }
`;

const DefaultCoachBody = css`
  height: 230px;

  > div {
    position: absolute;
    bottom: 58px;

    ${fontStyle}
  }

  > button {
    position: absolute;
    bottom: 0;
  }
`;

const OledCoachBody = css`
  height: 335px;

  > button {
    position: absolute;
    top: 49px;

    ${BlinkCss}
    letter-spacing: 1.2px;
  }
`;

export const OledBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 0;
  gap: 15px;

  ${fontStyle}

  > button {
    width: 311px;
    letter-spacing: 1.4px;
  }
`;

export const OledSubExplain = styled.div`
  position: absolute;
  top: 90px;
  left: calc(50vw - 135px);

  ${fontStyle};
`;

export const ArrowBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 76px;

  > svg:nth-of-type(2) {
    transform: rotate(180deg);
  }
`;

export const CongratulationBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  position: absolute;
  bottom: 130px;

  width: 100%;
  ${fontStyle}
`;

export const CongratulationBody = styled.div<{ $isOled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 75px;

  width: 100vw;
  height: calc(100vh - 334px - 53px);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    width: 100%;

    ${(props) => (props.$isOled ? OledCoachBody : DefaultCoachBody)}
  }
`;

export const CongratulationHeader = styled.div`
  position: absolute;
  top: 26.5px;
  right: 20px;
`;

export const OtsProGuide = styled.div`
  position: absolute;
  bottom: 0px;

  ${typoOne400C}
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => props.theme.mainColor};
`;

export const OtsProArea = styled.div`
  display: flex;
  justify-content: center;

  position: relative;

  width: 100%;
  height: 388px;

  > svg {
    position: absolute;
    bottom: 139px;
  }
`;

export const OtsProContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 0;

  width: inherit;
  height: calc(100% - 100px);
`;

export const ExperienceGuide = styled.div`
  width: 100%;

  text-align: center;
  ${fontStyle}
`;

export const ExperienceContainer = styled.div<{ $isUnder: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;

  position: absolute;
  bottom: ${({ $isUnder }) => ($isUnder ? 113 : 113 + 23)}px;

  width: 100%;

  > button {
    padding-inline: 10px;
  }
`;

export const CoachContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zindex.cover};

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7) !important;

  &.homecinema {
    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
  }
`;

export const OvalContainer = styled.div`
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  svg:nth-of-type(2) {
    top: 9px;
    left: 9px;
  }
`;

export const PointContainer = styled.div<{
  $position: RuleSet<object>;
  $fingerfilled: boolean;
}>`
  position: absolute;
  ${(props) => props.$position}

  > img {
    position: absolute;

    ${(props) =>
      props.$fingerfilled
        ? css`
            top: -2px;
            left: 11px;
          `
        : css`
            top: 1px;
            left: 9px;
          `}

    width: 80px;
  }
`;
