import { typoSharpSansMediumBold } from "constants/theme/fonts";
import styled from "styled-components";

export const LineupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 27px;

  text-align: center;
  ${typoSharpSansMediumBold}
  font-size: 20px;
  line-height: 140%;
`;
