import { OtsModalProps } from "types/common";
import Modal from "../Modal";
import { MainTitle, SubTitle, TitleContainer } from "constants/theme/globalStyles";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { NowPoint, OtsContainer, PointBox, UnderExplain } from "./index.style";
import Pointed from "layout/CoachLayout/Pointed";
import { OtsProMiddleIcon } from "constants/icons";
import Draggable from "react-draggable";
import OtsProCoach from "layout/CoachLayout/OtsProCoach";
import { useSocket } from "core/contexts/SocketContext";
import { EVENTS } from "constants/enum/events";
import { COMMANDS } from "constants/enum/commands";
import useTimeout from "core/hooks/useTimer";
import { useTranslation } from "react-i18next";

const OtsProModal = ({ isModalOpen, modalCloseHandler }: OtsModalProps) => {
  const { t } = useTranslation();
  const [nowPos, setNowPos] = useState({ x: 119, y: 100 });
  const [isCoach, setIsCoach] = useState<boolean>(true);
  const { socket } = useSocket();
  const { setAsync } = useTimeout();

  const [otsStatus, setOtsStatus] = useState<"OTS_DISABLE" | "OTS_ENABLE">("OTS_DISABLE");
  const containerRef = useRef<HTMLDivElement>(null);

  const nowPositionHandler = (dragEvent: any) => {
    setNowPos({ x: dragEvent.x + 119, y: dragEvent.y + 100 });
  };

  const emitPosition = useCallback(async () => {
    if (otsStatus !== "OTS_DISABLE") return;
    setOtsStatus(() => "OTS_ENABLE");
    socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, {
      command: COMMANDS.EXPERIENCE_DATA,
      data: nowPos,
    });
    await setAsync(20);
    setOtsStatus(() => "OTS_DISABLE");
  }, [nowPos]);

  useEffect(() => {
    emitPosition();
  }, [emitPosition]);

  return isModalOpen !== null ? (
    <>
      {isCoach && <OtsProCoach coachHandler={() => setIsCoach(false)} />}
      <Modal isModalOpen={isModalOpen} isExperience modalCloseHandler={modalCloseHandler}>
        <OtsContainer>
          <TitleContainer>
            <SubTitle>{t("sound_start_ots_pro_subtitle")}</SubTitle>
            <MainTitle>{t("sound_start_ots_pro_title")}</MainTitle>
          </TitleContainer>
          <PointBox ref={containerRef}>
            <Draggable
              bounds="parent"
              onStart={(_, dragEvent) => nowPositionHandler(dragEvent)}
              onDrag={(_, dragEvent) => nowPositionHandler(dragEvent)}
              onStop={(_, dragEvent) => nowPositionHandler(dragEvent)}
            >
              <NowPoint>
                <Pointed positionCss={`top:0; left:0`} fingerImage={false} />
              </NowPoint>
            </Draggable>
            <OtsProMiddleIcon />
          </PointBox>
          <UnderExplain>{t("sound_start_ots_pro_guide")}</UnderExplain>
        </OtsContainer>
      </Modal>
    </>
  ) : null;
};

export default memo(OtsProModal);
