import { palette } from "constants/theme";
import {
  typoOneBold,
  typoSharpSansRegular,
} from "constants/theme/fonts";
import styled, { css } from "styled-components";

const fontCss = css`
  ${typoSharpSansRegular}
  font-size: 17px;
  line-height: 135%;
  text-align: center;
`;

const buttonCss = css`
  width: 139px;
  height: 44px;

  border-radius: 26px;
  border: none;
  ${typoOneBold}
  font-size: 14px;
  letter-spacing: 5px;
  line-height: 100%;
`;

export const SubModalError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  > svg {
    margin-bottom: 12px;
  }

  > div {
    margin-bottom: 24px;

    ${fontCss}
    color: ${palette.grayScale.dark_80};
  }

  > button {
    ${buttonCss}
    color: ${palette.grayScale.dark_0};
    background-color: ${palette.grayScale.dark_80};
  }
`;

export const TimerContainer = styled.div`
  position: absolute;
  left: 283px;
  bottom: 4px;

  color: #ff7800;
  font-size: 14px;
  line-height: 158%;
`;

export const InputCodeBox = styled.div`
  position: relative;

  width: 320px;
  padding-bottom: 4px;
  margin-block: 41px 48px;

  border-bottom: solid 2px ${palette.grayScale.dark_25};

  > input {
    width: 100%;

    background-color: transparent;
    border: none;

    text-align: center;
    font-size: 32px;
    color: ${palette.grayScale.dark_0};
    ${typoSharpSansRegular}
  }
`;

export const InputCodeButton = styled.button<{ $isActive: boolean }>`
  ${buttonCss}
  color: ${(props) =>
    props.$isActive ? palette.grayScale.dark_80 : palette.grayScale.dark_30};
  background-color: ${(props) =>
    props.$isActive ? palette.grayScale.dark_0 : palette.grayScale.dark_15};
`;

export const InputCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${fontCss}
`;

export const CannotEnterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;

  > div {
    ${fontCss}
  }
`;
