import { typoSharpSansBold } from "constants/theme/fonts";
import styled from "styled-components";

export const OfflineHeader = styled.div`
  width: calc(100% - 40px);
  margin: 30px 20px;

  ${typoSharpSansBold}
  font-size: 20px;
  line-height: 100%;
`;

export const OfflineContainer = styled.div``;
