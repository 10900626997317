import { palette } from "constants/theme";
import { typoOne700C, typoSharpSansBold } from "constants/theme/fonts";
import { ExplainText, MainTitle, SubTitle } from "constants/theme/globalStyles";
import styled from "styled-components";

export const AgainButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content !important;
  margin-bottom: 20px;

  ${typoOne700C}
  font-size: 16px !important;
  line-height: 125%;
  color: ${palette.grayScale.dark_0};
  background-color: transparent;
  border: none;
`;

export const MoreFeaturesTitle = styled.div<{ $isUnder: boolean }>`
  width: 100%;

  text-align: center;
  margin-block: ${({ $isUnder }) => ($isUnder ? "21px 3vh" : "15px 1vh")};

  ${typoSharpSansBold}
  font-size: 16px;
  line-height: 138%;
`;

export const MoreFeaturesContainer = styled.div<{ $isUnder: boolean }>`
  position: absolute;
  bottom: ${({ $isUnder }) => ($isUnder ? 55 : -20)}px;
  width: 100vw;
  height: fit-content;
  padding-bottom: 3vh;

  border-radius: 45px 45px 0 0;
  background-color: ${palette.grayScale.dark_75};
`;

export const OledContainer = styled.div`
  > ${MainTitle} {
    color: ${(props) => props.theme.mainColor};
    margin-bottom: 10px;
    font-size: 30px;
  }

  > ${SubTitle} {
    margin-bottom: 9px;

    line-height: 140%;
    color: ${palette.grayScale.dark_0};
    ${typoSharpSansBold}
  }

  > button {
    width: 311px;
    letter-spacing: 1.4px;
  }

  > svg {
    margin-bottom: 25px;
  }
`;

export const DefaultContainer = styled.div`
  > ${MainTitle} {
    color: ${(props) => props.theme.mainColor};
    margin-bottom: 32px;
  }

  > ${ExplainText}:nth-of-type(3) {
    margin-block: 11px 40px;

    opacity: 1;
    font-size: 16px;
    line-height: 137.5%;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - 334px - 53px);

  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const CongratulationContainer = styled.div``;
