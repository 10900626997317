import Modal from "layout/Modal/Modal";
import { MainTitle, SubTitle, TitleContainer } from "constants/theme/globalStyles";
import {
  AnimationImage,
  BottomBar,
  BottomButtonBox,
  BottomMenu,
  HomeCinemaContainer,
  MovieCard,
  MovieContainer,
  MovieImage,
  MovieTitle,
  SwipeGuide,
} from "./index.style";
import { useEffect, useRef, useState } from "react";
import { homecinemaList, homecinemaListSCIC } from "./constant";

import Flicking, { FlickingError } from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import { LeftArrowIcon } from "constants/icons";
import { EVENTS } from "constants/enum/events";
import { COMMANDS } from "constants/enum/commands";
import HomecinemaCoach from "layout/CoachLayout/HomecinemaCoach";
import { useSocket } from "core/contexts/SocketContext";
import { DefaultModalProps } from "types/common";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { tvEnvState } from "core/store/tvEnvState";

const START_IDX = 1;

const HomecinemaModal = ({ isModalOpen, modalCloseHandler }: DefaultModalProps) => {
  const { SUBSIDIARY } = useRecoilValue(tvEnvState);
  const [isCoach, setIsCoach] = useState<boolean>(true);
  const [curIndex, setCurIndex] = useState(0);
  const [selectedMovie, setSelectedMovie] = useState<number>(-1);
  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const [isQSymphonyOn, setIsQSymphonyOn] = useState<boolean>(false);
  const flickingRef = useRef<Flicking | null>(null);
  const { socket } = useSocket();
  const { t } = useTranslation();

  const offQSymphony = () => {
    setIsQSymphonyOn(false);
    socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, {
      command: COMMANDS.EXPERIENCE_DATA,
      isQSymphonyOn: false,
    });
  };
  const onQSymphony = () => {
    setIsQSymphonyOn(true);
    socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, {
      command: COMMANDS.EXPERIENCE_DATA,
      isQSymphonyOn: true,
    });
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    if (e.touches.length === 1) {
      setTouchStartY(e.touches[0].clientY);
    }
  };

  const handleTouchEnd = (e: React.TouchEvent, index: number) => {
    if (touchStartY !== null) {
      const touchEndY = e.changedTouches[0].clientY;
      const deltaY = touchEndY - (touchStartY as number);

      if (deltaY < -100) {
        setSelectedMovie(index);
        socket.emit(EVENTS.SCENARIO_MOBILE_TO_TV, {
          command: COMMANDS.EXPERIENCE_DATA,
          homeCinemaIndex: index,
        });

        moveToFlicking(index);
      } else if (deltaY > 10) {
        setSelectedMovie(-1);
      }

      setTouchStartY(null);
    }
  };

  const moveToFlicking = (index: number) => {
    const flicking = flickingRef.current;

    if (!flicking) {
      return;
    }

    flicking.moveTo(index).catch((err) => {
      if (err instanceof FlickingError) return;
      throw err;
    });
  };

  useEffect(() => {
    flickingRef.current?.prev().catch((err) => {
      if (err instanceof FlickingError) return;
      throw err;
    });

    flickingRef.current?.next().catch((err) => {
      if (err instanceof FlickingError) return;
      throw err;
    });

    return () => {};
  }, [flickingRef.current]);

  return (
    <>
      {isModalOpen !== null ? (
        <>
          {isCoach && (
            <HomecinemaCoach
              coachHandler={() => {
                setIsCoach(false);
              }}
            />
          )}
          <Modal isModalOpen={isModalOpen} isExperience modalCloseHandler={modalCloseHandler}>
            <HomeCinemaContainer>
              <TitleContainer>
                <SubTitle>{t("sound_start_home_cinema_subtitle")}</SubTitle>
                <MainTitle>{t("sound_start_home_cinema_title")}</MainTitle>
              </TitleContainer>
              <MovieContainer>
                <Flicking
                  align={{ camera: "100%", panel: "20%" }}
                  ref={flickingRef}
                  defaultIndex={START_IDX}
                  circular={false}
                  onChanged={(e) => {
                    setCurIndex(e.index);
                  }}
                >
                  {(SUBSIDIARY === "SCIC" ? homecinemaListSCIC : homecinemaList).map((elem, idx) => {
                    return (
                      <MovieCard
                        key={idx}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={(e) => handleTouchEnd(e, idx)}
                        $isPlay={selectedMovie >= 0}
                        $isSelected={selectedMovie >= 0 && selectedMovie === idx}
                        $isCoach={isCoach && idx === START_IDX}
                      >
                        <MovieImage>
                          {selectedMovie === idx && (
                            <AnimationImage>
                              <img src={elem.image} alt={`${idx}`} />
                            </AnimationImage>
                          )}
                          <img src={elem.image} alt={`${idx}`} />
                        </MovieImage>
                        {(selectedMovie === idx || (isCoach && idx === START_IDX)) && (
                          <>
                            <MovieTitle>{t(elem.cover)}</MovieTitle>
                            <SwipeGuide>
                              <div>{t("sound_home_cinema_now_playing")}</div>
                            </SwipeGuide>
                          </>
                        )}
                        {!isCoach && curIndex === idx && selectedMovie !== idx && (
                          <SwipeGuide>
                            <LeftArrowIcon />
                            <div>{t("sound_home_cinema_swipe_up")}</div>
                          </SwipeGuide>
                        )}
                        {!isCoach && curIndex === idx && selectedMovie !== idx && <div>{t(elem.name)}</div>}
                      </MovieCard>
                    );
                  })}
                </Flicking>
              </MovieContainer>
              <BottomMenu>
                <BottomBar>
                  <div />
                </BottomBar>
                <div>{t("sound_home_cinema_qsymphony_title")}</div>
                <BottomButtonBox $isQSymphonyOn={isQSymphonyOn}>
                  <button onClick={offQSymphony}>{t("set_off")}</button>
                  <button onClick={onQSymphony}>{t("set_on")}</button>
                </BottomButtonBox>
              </BottomMenu>
            </HomeCinemaContainer>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default HomecinemaModal;
