import { getAyncs } from "core/util/api/apiCore";

export const getShowAuth = async (DUID: string) => {
  const response = await getAyncs(`/public/tvs/show-auth/${DUID}`);

  return response;
};

export const getCheckAuth = async (DUID: string, authid: string) => {
  const response = await getAyncs(`/public/tvs/check-auth/${DUID}/${authid}`);

  return response;
};
