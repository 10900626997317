import { palette } from "constants/theme";
import { typoOne400C } from "constants/theme/fonts";
import {
  MainTitle,
  SubTitle,
  TitleContainer,
} from "constants/theme/globalStyles";
import styled from "styled-components";

export const UnderExplain = styled.div`
  margin-top: 15px;

  ${typoOne400C}
  font-size: 16px;
  line-height: 150%;
`;

export const NowPoint = styled.div`
  position: absolute;

  width: 51px;
  height: 51px;

  overflow: visible;
`;

export const PointBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 289px;
  height: 251px;

  position: relative;

  background-color: #eee;
  border-radius: 10px;
`;

export const OtsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > ${TitleContainer} > ${SubTitle} {
    color: ${palette.grayScale.dark_0};
  }

  > ${TitleContainer} > ${MainTitle} {
    margin-bottom: 20px;

    color: ${(props) => props.theme.mainColor};
    font-size: 30px;
  }
`;
