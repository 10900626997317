import { memo, useState } from "react";
import { ElementContainer } from "./index.style";
import { ExplainText, MainTitle } from "constants/theme/globalStyles";
import {
  ChooseTvTitle,
  TvSizeContainer,
  TvSizeElement,
} from "./ChooseTv.style";
import { useSocket } from "core/contexts/SocketContext";
import { useTranslation } from "react-i18next";


interface IProps {
  tvSize: number;
}

const ChooseTv = ({ tvSize }: IProps) => {
  const { t } = useTranslation();
  const { sizeSimulatorScreenSize } = useSocket();
  const [selectSize, setSelectSize] = useState(0);

  return (
    <ElementContainer>
      <ChooseTvTitle>
        <MainTitle>{t("size_simulator_choose_tv_title")}</MainTitle>
        <ExplainText>{t("size_simulator_choose_tv_guide")}</ExplainText>
      </ChooseTvTitle>
      <TvSizeContainer>
        {[65, 75, 85, 98].map((elem, idx) => {
          return (
            <TvSizeElement
              key={idx}
              $isPrimary={selectSize === elem}
              $isCanUse={elem <= tvSize}
              onClick={() => {
                if (elem > tvSize || selectSize === elem) return;
                setSelectSize(elem);
                sizeSimulatorScreenSize(`${elem}`);
              }}
            >
              {elem}”
            </TvSizeElement>
          );
        })}
      </TvSizeContainer>
    </ElementContainer>
  );
};

export default memo(ChooseTv);
