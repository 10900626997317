import { ApiErrorResponse, CreateTvDto } from "types/api/api";
import axios from "axios";
import { getAyncs, postAyncs } from "./apiCore";

// TODO: 사용하는 곳에서 api 에러가 나도 진행됨 (containers/register/index.tsx)
export const tvRegisterApi = async <T>(createTvDto: CreateTvDto, SOCKET_URL: string): Promise<T | ApiErrorResponse> => {
  try {
    const socketWithOutWs = SOCKET_URL.replace("/ws", "");
    const data = await axios.post(`${socketWithOutWs}/events/register`, createTvDto);
    return data.data;
  } catch (error: any) {
    return error.response.data as ApiErrorResponse;
  }
};

/**
 * TV DUID를 이용하여 등록된 데이터를 가져오는 함수
 * 등록되지 않았거나 등록에 실패할 경우 isSuccess는 false, data는 빈 값을 반환
 * @param {string} DUID TV DUID 값
 * @return TV 등록 정보
 */
export const getTvsByDUID = async (DUID: string) => {
  const response = await getAyncs(`/public/tvs/duid/${DUID}`);

  // 등록되지 않은 DUID의 경우 []를 반환하므로 isSuccess를 false로 설정
  if (response.data.length === 0) {
    return { isSuccess: false, data: [] };
  }

  return response;
};

/**
 * 언어팩을 가져오는 함수
 * @param {string} projectName 프로젝트 이름
 * @param {string} subsidiary Subsidiary
 */
export const getLanguage = async (projectName: string, subsidiary: string) => {
  const response = await getAyncs(`/public/mobile/languages?projectName=${projectName}&subsidiaryCode=${subsidiary}`);

  return response;
};

export const getRegion = async (code: string) => {
  const response = await getAyncs(`/public/mobile/regions/${code}`);

  return response;
};

export const postLinkAccess = async (DUID: string, type: "productdetailpage" | "magazinelink" | "smartthings") => {
  const { isSuccess } = await postAyncs(`/public/mobile/logs/link-access`, {
    duid: DUID,
    type: type,
  });

  return isSuccess;
};

export const postMobileStartExp = async (DUID: string, type: "sound" | "picture" | "oled") => {
  const { isSuccess } = await postAyncs(`/public/mobile/logs/start-exp`, {
    duid: DUID,
    type: type,
  });
  return isSuccess;
}
  
export const getPdpBySubsidiary = async (subsidiary: string) => {
  const response = await getAyncs(`/public/mobile/subsidiary/${subsidiary}`);

  return response;
};
