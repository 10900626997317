import { useEffect, useRef, useState } from "react";
import NoActiveModal from "layout/Modal/NoActiveModal";
import { useSocket } from "core/contexts/SocketContext";
import { EVENTS } from "constants/enum/events";
import useTimeout from "core/hooks/useTimer";
import { useRecoilState, useRecoilValue } from "recoil";
import { noActiveState } from "core/store/activeState";
import { useNavigate } from "react-router-dom";
import { tvEnvState } from "core/store/tvEnvState";

interface IProps {
  children: React.ReactNode;
}

const ActiveMonitor = ({ children }: IProps) => {
  const { SCENARIO } = useRecoilValue(tvEnvState);
  const navigate = useNavigate();
  const [isActiveModalOpen, setIssActiveModalOpen] = useRecoilState(noActiveState);
  const { setAsync } = useTimeout();
  const { socket, scenarioEnd, isConnected } = useSocket();
  const [leftTime, setLeftTime] = useState(60);

  const timer = useRef<NodeJS.Timeout>();

  const modalClose = async () => {
    setIssActiveModalOpen(() => false);
    await setAsync(500);
    setIssActiveModalOpen(() => null);
  };

  const clickEvent = async () => {
    socket.emit(EVENTS.ACTIVE_MONITOR);
    await modalClose();
    clearInterval(timer.current);
    setLeftTime(60);
    removeClickEventListener();
  };

  const removeClickEventListener = () => {
    window.removeEventListener("click", clickEvent);
    window.removeEventListener("touchstart", clickEvent);
  };

  const resetTimer = () => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      setLeftTime((prev) => {
        if (prev <= 0) {
          modalClose();
          clearInterval(timer.current);
          removeClickEventListener();
          return 0;
        }
        return prev > 0 ? prev - 1 : prev;
      });
    }, 1000);
  };

  const endScenario = () => {
    scenarioEnd();
    modalClose();
    navigate(`/complete?scenario=${SCENARIO}`);
    return;
  };

  useEffect(() => {
    if (leftTime <= 0) endScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftTime]);

  useEffect(() => {
    if (!isConnected) endScenario();
  }, [isConnected]);

  useEffect(() => {
    if (!socket) return;

    const activeMonitorListener = (data: any) => {
      if (!data.leftTime) return;
      window.addEventListener("click", clickEvent);
      window.addEventListener("touchstart", clickEvent);
      setLeftTime(() => data.leftTime);
      setIssActiveModalOpen(() => true);
      resetTimer();
    };

    socket.on(EVENTS.ACTIVE_MONITOR, activeMonitorListener);

    return () => {
      socket.off(EVENTS.ACTIVE_MONITOR, activeMonitorListener);
      clearInterval(timer.current);
      removeClickEventListener();
    };
  }, []);

  return (
    <>
      {children}
      <NoActiveModal leftTime={leftTime} isModalOpen={isActiveModalOpen} />
    </>
  );
};

export default ActiveMonitor;
