import { useTheme } from "core/contexts/ThemeContext";
import { DefalutBackgroundContainer } from "./index.style";

const DefaultBackground = () => {
  const { theme } = useTheme();

  return (
    <DefalutBackgroundContainer $bgLinear={theme.background.baseBg}>
      <img src={theme.background.img} alt="background pattern" />
    </DefalutBackgroundContainer>
  );
};

export default DefaultBackground;
