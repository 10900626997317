import { palette } from "constants/theme";
import { typoOne400C } from "constants/theme/fonts";
import styled from "styled-components";

export const MenuCoachBox = styled.div`
  ${typoOne400C}
  font-size: 16px;
  line-height: 137.5%;
  color: ${(props) => props.theme.mainColor};
`;

export const MenuButton = styled.button<{ $isCoach: boolean }>`
  width: 44px;
  height: 44px;

  background-color: ${(props) =>
    props.$isCoach ? props.theme.mainColor : palette.grayScale.dark_0};
  border: none;
  border-radius: 50%;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  width: fit-content;
`;
