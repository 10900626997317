import { useEffect, useState } from "react";

export function useLandScape() {
  const [isLandscape, setIsLandscape] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation:landscape)").matches) {
        setIsLandscape(true);
      } else {
        setIsLandscape(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isLandscape;
}
