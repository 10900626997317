import OfflineQrModal from "layout/Modal/OfflineQrModal";
import { OfflineContainer, OfflineHeader } from "./index.style";
import { useEffect, useState } from "react";
import { getShowAuth } from "core/util/api/offlineQr";
import CannotEnter from "layout/Modal/OfflineQrModal/CannotEnter";
import InputCode from "layout/Modal/OfflineQrModal/InputCode";
import { experienceTvType } from "types/common";
import CompleteLayout from "layout/CompleteLayout";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { tvEnvState } from "core/store/tvEnvState";

const OfflineQr = () => {
  const [isAbleAccess, setIsAbleAccess] = useState<boolean>(true);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [tvEnv, setTvEnv] = useRecoilState(tvEnvState);
  const [scenario, setScenario] = useState<experienceTvType>("picture4k");

  const { t } = useTranslation();

  const accessHandler = async () => {
    const { isSuccess, data } = await getShowAuth(tvEnv.DUID as string);

    if ((isSuccess && data.status) || !isSuccess) {
      switch (data.status) {
        case 480: // InvalidDuid
          // 후에 모달 추가될 수도 있음
          break;
        case 481: // WrongAuthCode
          break;
        case 482: // AlreadyInUse
          cannotAccessHandler();
          break;
        default:
          break;
      }

      return;
    }

    setTvEnv((prev) => {
      return { ...prev, SCENARIO: data.scenario };
    });
    setScenario(data.scenario);
  };

  const expiredHandler = () => {
    setIsExpired(true);
  };

  const cannotAccessHandler = () => {
    setIsAbleAccess(false);
  };

  useEffect(() => {
    if (tvEnv.DUID && (tvEnv.SCENARIO as string) === "offlineQr") {
      accessHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tvEnv]);

  return isExpired ? (
    <CompleteLayout scenario={scenario} />
  ) : (
    <OfflineContainer>
      <OfflineHeader>{t("offlineqr_header")}</OfflineHeader>

      <OfflineQrModal isModalOpen={isAbleAccess}>
        {isAbleAccess ? (
          <InputCode DUID={tvEnv.DUID} expiredHandler={expiredHandler} cannotAccessHandler={cannotAccessHandler} />
        ) : (
          <CannotEnter />
        )}
      </OfflineQrModal>
    </OfflineContainer>
  );
};

export default OfflineQr;
