import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import enJSON from "i18n/en.json";

/**
 * 받아오는 명령 값을 가공하는 함수
 * 명령을 받을 때 불필요한 문자들이 함께 포함되어 오는 경우가 있었음. 이를 제거해주는 함수
 * @param {any} value 가공할 데이터
 */
const setFormat = (value: any, format?: string) => {
  if (format === "bold") {
    return value.replace(/\[\[/g, "<0>").replace(/\]\]/g, "</0>");
  }
  if (format === "bold-force") {
    if (value.indexOf("[[") === -1) {
      return `<0>${value}</0>`;
    }
    return value.replace(/\[\[/g, "<0>").replace(/\]\]/g, "</0>");
  }
};

export const initI18n = async () => {
  await i18next.use(initReactI18next).init({
    resources: { init: { translation: enJSON } },
    lng: "init",
    fallbackLng: "init",
    react: {
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
    },
    interpolation: {
      escapeValue: false,
      format: setFormat,
    },
  });
};
