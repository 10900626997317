import Modal from "layout/Modal/Modal";
import { WarningCircleIcon } from "constants/icons";
import { NoActiveContainer } from "./index.style";
import { palette } from "constants/theme";
import { useSetRecoilState } from "recoil";
import { noActiveState } from "core/store/activeState";
import { useTranslation } from "react-i18next";

interface IProps {
  leftTime: number;
  isModalOpen: boolean | null;
}

const NoActiveModal = ({ leftTime, isModalOpen }: IProps) => {
  const { t } = useTranslation();
  const activeStateHandler = useSetRecoilState(noActiveState);

  return (
    <>
      {isModalOpen !== null && isModalOpen && (
        <Modal isModalOpen={isModalOpen} modalCloseHandler={activeStateHandler}>
          <NoActiveContainer>
            <WarningCircleIcon color={palette.grayScale.dark_0} />
            {t("no_active", { leftTime: leftTime })}
          </NoActiveContainer>
        </Modal>
      )}
    </>
  );
};

export default NoActiveModal;
