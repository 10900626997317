import { palette, zindex } from "constants/theme";
import { typoSharpSansMediumBold } from "constants/theme/fonts";
import styled, { css } from "styled-components";

export const BNBTitle = styled.div`
  text-align: center;

  ${typoSharpSansMediumBold}
  font-size: 10px;
  line-height: 100%;
`;

export const BNBElement = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  background-color: transparent;
  color: ${palette.grayScale.dark_0};
  border: none;
`;

export const BNBContainer = styled.div<{ $isOutro: boolean; $isUnder: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 90%;
  padding: ${({ $isUnder }) => (!$isUnder ? "20px 5% 20px" : " 0 5% 20px")};

  background-color: ${({ $isUnder, $isOutro }) =>
    $isOutro ? ($isUnder ? palette.grayScale.dark_75 : "rgba(0,0,0,0.3)") : "transparents"};

  ${({ $isUnder, $isOutro }) =>
    !$isUnder &&
    $isOutro &&
    css`
      position: absolute;
      bottom: 0;
      z-index: ${zindex.modal};
    `}
`;
