import { tvEnvState } from "core/store/tvEnvState";
import CompleteLayout from "layout/CompleteLayout";
import { useRecoilValue } from "recoil";

const Complete = () => {
  const { SCENARIO } = useRecoilValue(tvEnvState);
  return <CompleteLayout scenario={SCENARIO} />;
};

export default Complete;
