import styled from "styled-components";

export const ElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > svg > path {
  }
`;
