import { scenarioTheme } from "constants/scenarioTheme";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from "react";
import { GlobalThemeTypes } from "types/theme";

interface IThemeContext {
  theme: GlobalThemeTypes;
  setTheme: React.Dispatch<React.SetStateAction<GlobalThemeTypes>>;
}

export const ThemeContext = createContext<any>({});

export const ThemeProvider: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const [curTheme, setCurTheme] = useState<GlobalThemeTypes>(
    scenarioTheme.picture4k
  );

  return (
    <ThemeContext.Provider value={{ theme: curTheme, setTheme: setCurTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): IThemeContext => {
  const ctx = useContext(ThemeContext);
  if (ctx === undefined) {
    throw new Error("useTheme can only be used inside ThemeContext");
  }
  return ctx;
};
