import { GlobalThemeTypes } from "types/theme";
import { palette } from "./theme";
import * as MoreFeatureIcon from "./icons/moreFeature";

import SoundBg from "assets/background/bg_sound.png";
import PictureBg from "assets/background/bg_picture.png";
import OledBg from "assets/background/bg_oled.png";

import WelcomeOledBg from "assets/welcome/bg_oled.png";
import WelcomePictureBg from "assets/welcome/bg_picture.png";
import WelcomeSoundBg from "assets/welcome/bg_sound.png";

import * as StartLayoutIcon from "./icons/startLayout";
import { STEP } from "./enum/step";
import { SCENARIO_STEP } from "./enum/scenarioStep";

interface ScenarioThemeTypes {
  picture4k: GlobalThemeTypes;
  picture8k: GlobalThemeTypes;
  sound: GlobalThemeTypes;
  oled: GlobalThemeTypes;
  smartthings: GlobalThemeTypes;
}

export const scenarioTheme: ScenarioThemeTypes = {
  picture4k: {
    background: {
      baseBg: `
      background: linear-gradient(
        187deg,
        #14361E 3.39%,
        #161515 98.5%
      );
    `,
      img: PictureBg,
    },
    headerTitle: "picture4k_header",
    SNBList: [
      {
        text: "picture4k_snb_1",
        targetStep: { step: STEP.HDR, scenarioStep: SCENARIO_STEP.EXPERIENCE },
      },
      {
        text: "picture4k_snb_2",
        targetStep: {
          step: STEP.MATRIX,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "picture_snb_morefeatures",
        targetStep: { step: STEP.OUTRO, scenarioStep: SCENARIO_STEP.START },
      },
    ],
    SliderList: {
      left: { label: "picture_slider_left", color: palette.grayScale.dark_60 },
      center: { label: "picture_slider_center", color: palette.picture.ochre },
      right: { label: "picture_slider_right", color: palette.picture.yellow },
    },
    MoreFeatureList: [
      {
        title: "picture4k_morefeature_1",
        icon: <MoreFeatureIcon.Picture1to4k />,
      },
      {
        title: "picture4k_morefeature_2",
        icon: <MoreFeatureIcon.Picture2to4k />,
      },
      {
        title: "picture4k_morefeature_3",
        icon: <MoreFeatureIcon.Picture3to4k />,
      },
    ],
    StartLayouts: {
      [STEP.HDR]: {
        subTitle: "picture4k_start_first_subtitle",
        mainTitle: "picture4k_start_first_title",
        explain: "picture4k_start_first_explain",
        icon: <StartLayoutIcon.NQ4 />,
      },
      [STEP.MATRIX]: {
        subTitle: "picture4k_start_second_subtitle",
        mainTitle: "picture4k_start_second_title",
        icon: <StartLayoutIcon.AiUpscaling4k />,
      },
    },
    StepList: [STEP.COVER, STEP.HDR, STEP.MATRIX, STEP.OUTRO],
    ExperienceStepList: [SCENARIO_STEP.START, SCENARIO_STEP.SKIP, SCENARIO_STEP.BRIDGE, SCENARIO_STEP.EXPERIENCE],
    color: {
      mainColor: palette.picture.yellow,
      soundBtn: {
        btnBg: palette.picture.yellow,
        linear: {
          leftTop: "#C18F1E",
          rightDown: palette.picture.yellow,
        },
      },
      moreFeature: {
        default: {
          iconBoxShadow: {
            leftTop: "#C4872B",
            rightDown: "#09111C",
          },
          iconBgLinear: {
            leftTop: palette.picture.yellow,
            rightDown: "#9A741E",
          },
          bgLinear: {
            leftTop: "#222623",
            rightDown: palette.grayScale.dark_70,
          },
        },
        select: {
          iconBoxShadow: {
            leftTop: "#FFD953",
            rightDown: "#5A4A10",
          },
          iconBgLinear: {
            leftTop: "#222623",
            rightDown: "#222623",
          },
          bgLinear: {
            leftTop: palette.picture.yellow,
            rightDown: "#9A741E",
          },
        },
      },
      icon: {
        primary: palette.picture.yellow,
        background: palette.grayScale.dark_0,
        onBackground: palette.grayScale.dark_80,
        soundButton: palette.grayScale.dark_55,
      },
    },
    CoverLayout: {
      image: WelcomePictureBg,
      title: "picture4k_cover",
    },
  },
  picture8k: {
    background: {
      baseBg: `
      background: linear-gradient(
        187deg,
        #14361E 3.39%,
        #161515 98.5%
      );
    `,
      img: PictureBg,
    },
    headerTitle: "picture8k_header",
    SNBList: [
      {
        text: "picture8k_snb_1",
        targetStep: { step: STEP.HDR, scenarioStep: SCENARIO_STEP.EXPERIENCE },
      },
      {
        text: "picture8k_snb_2",
        targetStep: {
          step: STEP.MATRIX,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "picture_snb_morefeatures",
        targetStep: { step: STEP.OUTRO, scenarioStep: SCENARIO_STEP.START },
      },
    ],
    SliderList: {
      left: { label: "picture_slider_left", color: palette.grayScale.dark_60 },
      center: { label: "picture_slider_center", color: palette.picture.ochre },
      right: { label: "picture_slider_right", color: palette.picture.yellow },
    },
    MoreFeatureList: [
      {
        title: "picture8k_morefeature_1",
        icon: <MoreFeatureIcon.Picture1to8k />,
      },
      {
        title: "picture8k_morefeature_2",
        icon: <MoreFeatureIcon.Picture2to8k />,
      },
      {
        title: "picture8k_morefeature_3",
        icon: <MoreFeatureIcon.Picture3to8k />,
      },
    ],
    StartLayouts: {
      [STEP.HDR]: {
        subTitle: "picture8k_start_first_subtitle",
        mainTitle: "picture8k_start_first_title",
        explain: "picture8k_start_first_explain",
        icon: <StartLayoutIcon.NQ8 />,
      },
      [STEP.MATRIX]: {
        subTitle: "picture8k_start_second_subtitle",
        mainTitle: "picture8k_start_second_title",
        icon: <StartLayoutIcon.AiUpscaling8k />,
      },
    },
    StepList: [STEP.COVER, STEP.HDR, STEP.MATRIX, STEP.OUTRO],
    ExperienceStepList: [SCENARIO_STEP.START, SCENARIO_STEP.SKIP, SCENARIO_STEP.BRIDGE, SCENARIO_STEP.EXPERIENCE],
    color: {
      mainColor: palette.picture.yellow,
      soundBtn: {
        btnBg: palette.picture.yellow,
        linear: {
          leftTop: "#C18F1E",
          rightDown: palette.picture.yellow,
        },
      },
      moreFeature: {
        default: {
          iconBoxShadow: {
            leftTop: "#C4872B",
            rightDown: "#09111C",
          },
          iconBgLinear: {
            leftTop: palette.picture.yellow,
            rightDown: "#9A741E",
          },
          bgLinear: {
            leftTop: "#222623",
            rightDown: palette.grayScale.dark_70,
          },
        },
        select: {
          iconBoxShadow: {
            leftTop: "#FFD953",
            rightDown: "#5A4A10",
          },
          iconBgLinear: {
            leftTop: "#222623",
            rightDown: "#222623",
          },
          bgLinear: {
            leftTop: palette.picture.yellow,
            rightDown: "#9A741E",
          },
        },
      },
      icon: {
        primary: palette.picture.yellow,
        background: palette.grayScale.dark_0,
        onBackground: palette.grayScale.dark_80,
        soundButton: palette.grayScale.dark_55,
      },
    },
    CoverLayout: {
      image: WelcomePictureBg,
      title: "picture8k_cover",
    },
  },
  sound: {
    background: {
      baseBg: `
      background: linear-gradient(
        187deg,
        #0D0F27 3.55%, #161515 98.56%
      );
    `,
      img: SoundBg,
    },
    headerTitle: "sound_header",
    SNBList: [
      {
        text: "sound_snb_ots_pro",
        targetStep: {
          step: STEP.OTSPLUS,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "sound_snb_qsymphony",
        targetStep: {
          step: STEP.QSYMPHONY,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "sound_snb_homecinema",
        targetStep: {
          step: STEP.HOMECINEMA,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "sound_snb_morefeatures",
        targetStep: { step: STEP.OUTRO, scenarioStep: SCENARIO_STEP.START },
      },
    ],
    SliderList: {
      left: { label: "set_off", color: palette.grayScale.dark_60 },
      right: { label: "set_on", color: palette.sound.magenta },
    },
    MoreFeatureList: [
      {
        title: "sound_morefeature_1",
        icon: <MoreFeatureIcon.Sound1 />,
      },
      {
        title: "sound_morefeature_2",
        icon: <MoreFeatureIcon.Sound2 />,
      },
      {
        title: "sound_morefeature_3",
        icon: <MoreFeatureIcon.Sound3 />,
      },
    ],
    StartLayouts: {
      [STEP.OTSPLUS]: {
        subTitle: "sound_start_ots_pro_subtitle",
        mainTitle: "sound_start_ots_pro_title",
        icon: <StartLayoutIcon.OTSPro />,
      },
      [STEP.QSYMPHONY]: {
        subTitle: "sound_start_qsymphony_subtitle",
        mainTitle: "sound_start_qsymphony_title",
        icon: <StartLayoutIcon.QSymphony />,
      },
      [STEP.HOMECINEMA]: {
        subTitle: "sound_start_home_cinema_subtitle",
        mainTitle: "sound_start_home_cinema_title",
        icon: <StartLayoutIcon.HomeCinema />,
      },
    },
    StepList: [STEP.COVER, STEP.OTSPLUS, STEP.QSYMPHONY, STEP.HOMECINEMA, STEP.OUTRO],
    ExperienceStepList: [SCENARIO_STEP.START, SCENARIO_STEP.SKIP, SCENARIO_STEP.BRIDGE, SCENARIO_STEP.EXPERIENCE],
    color: {
      mainColor: palette.sound.magenta,
      soundBtn: {
        btnBg: palette.sound.magenta,
        linear: {
          leftTop: "#921163",
          rightDown: palette.sound.magenta,
        },
      },
      moreFeature: {
        default: {
          iconBoxShadow: {
            leftTop: "#333333",
            rightDown: "#070707",
          },
          iconBgLinear: {
            leftTop: palette.sound.magenta,
            rightDown: "#4E0433",
          },
          bgLinear: {
            leftTop: "#222623",
            rightDown: palette.grayScale.dark_70,
          },
        },
        select: {
          iconBoxShadow: {
            leftTop: palette.sound.magenta,
            rightDown: "#310622",
          },
          iconBgLinear: {
            leftTop: "#222623",
            rightDown: "#222623",
          },
          bgLinear: {
            leftTop: palette.sound.magenta,
            rightDown: "#4E0433",
          },
        },
      },
      icon: {
        primary: palette.sound.magenta,
        background: palette.grayScale.dark_80,
        onBackground: palette.grayScale.dark_0,
        soundButton: palette.grayScale.dark_0,
      },
    },
    CoverLayout: {
      image: WelcomeSoundBg,
      title: "sound_cover",
    },
  },
  oled: {
    background: {
      baseBg: `
      background: linear-gradient(180deg, #00aba1 0%, #223578 52.6%, #000 100%);
    `,
      img: OledBg,
    },
    headerTitle: "oled_header",
    SNBList: [
      {
        text: "oled_snb",
        targetStep: {
          step: STEP.BLACKDETAIL,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "oled_snb_color_mapping",
        targetStep: {
          step: STEP.COLOR,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "oled_snb_morefeatures",
        targetStep: { step: STEP.OUTRO, scenarioStep: SCENARIO_STEP.START },
      },
    ],
    SliderList: {
      left: { label: "picture_slider_left", color: palette.oled.darkSeaBlue },
      center: { label: "picture_slider_center", color: palette.oled.emerald },
      right: { label: "oled_slider_right", color: palette.oled.lightEmerald },
    },
    MoreFeatureList: [
      { title: "oled_morefeature_1", icon: <MoreFeatureIcon.Oled1 /> },
      { title: "oled_morefeature_2", icon: <MoreFeatureIcon.Oled2 /> },
      {
        title: "oled_morefeature_3",
        icon: <MoreFeatureIcon.Oled3 />,
      },
    ],
    StartLayouts: {
      [STEP.BLACKDETAIL]: {
        subTitle: "oled_start_first_subtitle",
        mainTitle: "oled_start_first_title",
        explain: "oled_start_first_explain",
        icon: <StartLayoutIcon.QuantumHDR />,
      },
      [STEP.COLOR]: {
        subTitle: "oled_start_color_mapping_subtitle",
        mainTitle: "oled_start_color_mapping_title",
        explain: "oled_start_color_mapping_explain",
        icon: <StartLayoutIcon.PerceptionalColorMapping />,
      },
    },
    StepList: [STEP.COVER, STEP.BLACKDETAIL, STEP.COLOR, STEP.OUTRO],
    ExperienceStepList: [SCENARIO_STEP.START, SCENARIO_STEP.SKIP, SCENARIO_STEP.EXPERIENCE],
    color: {
      mainColor: palette.oled.aqua,
      soundBtn: {
        btnBg: palette.oled.aqua,
        linear: {
          leftTop: "#18A58C",
          rightDown: palette.oled.aqua,
        },
      },
      moreFeature: {
        default: {
          iconBoxShadow: {
            leftTop: palette.oled.emerald,
            rightDown: "#09111C",
          },
          iconBgLinear: {
            leftTop: palette.oled.lightEmerald,
            rightDown: palette.oled.emerald,
          },
          bgLinear: {
            leftTop: "#222623",
            rightDown: palette.grayScale.dark_70,
          },
        },
        select: {
          iconBoxShadow: {
            leftTop: palette.oled.lightEmerald,
            rightDown: "#113E41",
          },
          iconBgLinear: {
            leftTop: "#222623",
            rightDown: "#222623",
          },
          bgLinear: {
            leftTop: palette.oled.lightEmerald,
            rightDown: "#1E666B",
          },
        },
      },
      icon: {
        primary: palette.oled.aqua,
        background: palette.grayScale.dark_0,
        onBackground: palette.grayScale.dark_80,
        soundButton: palette.grayScale.dark_55,
      },
    },
    CoverLayout: {
      image: WelcomeOledBg,
      title: "oled_cover",
    },
  },
  smartthings: {
    background: {
      baseBg: `
      background: linear-gradient(180deg, #313131 0%, #111 100%);
    `,
      img: PictureBg,
    },
    headerTitle: "SmartThings",
    SNBList: [
      {
        text: "SmartThings",
        targetStep: {
          step: STEP.SMARTTHINGS,
          scenarioStep: SCENARIO_STEP.EXPERIENCE,
        },
      },
      {
        text: "More Features",
        targetStep: { step: STEP.OUTRO, scenarioStep: SCENARIO_STEP.START },
      },
    ],
    SliderList: {
      left: { label: "Conventional", color: palette.oled.darkSeaBlue },
      center: { label: "Compare", color: palette.oled.emerald },
      right: { label: "OLED", color: palette.oled.lightEmerald },
    },
    MoreFeatureList: [
      { title: "smartthings_morefeature_1", icon: <MoreFeatureIcon.Smartthing1 /> },
      { title: "smartthings_morefeature_2", icon: <MoreFeatureIcon.Smartthing2 /> },
      {
        title: "smartthings_morefeature_3",
        icon: <MoreFeatureIcon.Smartthing3 />,
      },
    ],
    StartLayouts: {},
    StepList: [STEP.COVER, STEP.SMARTTHINGS, STEP.OUTRO],
    ExperienceStepList: [SCENARIO_STEP.START, SCENARIO_STEP.SKIP, SCENARIO_STEP.EXPERIENCE],
    color: {
      mainColor: "#5BA7EE",
      soundBtn: {
        btnBg: "#5BA7EE",
        linear: {
          leftTop: "#326ea7",
          rightDown: "#5BA7EE",
        },
      },
      moreFeature: {
        default: {
          iconBoxShadow: {
            leftTop: "#070707",
            rightDown: "#070707",
          },
          iconBgLinear: {
            leftTop: "#5BA7EE",
            rightDown: "#00337E",
          },
          bgLinear: {
            leftTop: "#161515",
            rightDown: "#222623",
          },
        },
        select: {
          iconBoxShadow: {
            leftTop: "#001D47",
            rightDown: "#001D47",
          },
          iconBgLinear: {
            leftTop: "#222623",
            rightDown: "#222623",
          },
          bgLinear: {
            leftTop: "#5BA7EE",
            rightDown: "#00337E",
          },
        },
      },
      icon: {
        primary: "#5BA7EE",
        background: palette.grayScale.dark_0,
        onBackground: palette.grayScale.dark_80,
        soundButton: palette.grayScale.dark_55,
      },
    },
    CoverLayout: {
      image: WelcomeOledBg,
      title: "smartthings_cover",
    },
  },
};
