import Img1 from "assets/image/Homecinema_mobile_1.png";
import Img2 from "assets/image/Homecinema_mobile_2.png";
import Img3 from "assets/image/Homecinema_mobile_3.png";
import Img4 from "assets/image/Homecinema_mobile_4.png";
import Img5 from "assets/image/Homecinema_mobile_5.png";
import Img1_SCIC from "assets/image/Homecinema_mobile_1_SCIC.png";
import Img2_SCIC from "assets/image/Homecinema_mobile_2_SCIC.png";
import Img3_SCIC from "assets/image/Homecinema_mobile_3_SCIC.png";
import Img4_SCIC from "assets/image/Homecinema_mobile_4_SCIC.png";
import Img5_SCIC from "assets/image/Homecinema_mobile_5_SCIC.png";
import { HomeCinemaListType } from "types/common";

export const homecinemaList: HomeCinemaListType[] = [
  { name: "sound_home_cinema_movie_1", cover: "sound_home_cinema_movie_cover_1", image: Img1 },
  { name: "sound_home_cinema_movie_2", cover: "sound_home_cinema_movie_cover_2", image: Img2 },
  { name: "sound_home_cinema_movie_3", cover: "sound_home_cinema_movie_cover_3", image: Img3 },

  {
    name: "sound_home_cinema_movie_4",
    cover: "sound_home_cinema_movie_cover_4",
    image: Img4,
  },
  {
    name: "sound_home_cinema_movie_5",
    cover: "sound_home_cinema_movie_cover_5",
    image: Img5,
  },
];

export const homecinemaListSCIC: HomeCinemaListType[] = [
  { name: "sound_home_cinema_movie_1", cover: "sound_home_cinema_movie_cover_1", image: Img1_SCIC },
  { name: "sound_home_cinema_movie_2", cover: "sound_home_cinema_movie_cover_2", image: Img2_SCIC },
  { name: "sound_home_cinema_movie_3", cover: "sound_home_cinema_movie_cover_3", image: Img3_SCIC },

  {
    name: "sound_home_cinema_movie_4",
    cover: "sound_home_cinema_movie_cover_4",
    image: Img4_SCIC,
  },
  {
    name: "sound_home_cinema_movie_5",
    cover: "sound_home_cinema_movie_cover_5",
    image: Img5_SCIC,
  },
];
