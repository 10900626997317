import { atom } from "recoil";
import { TvEnvTypes } from "types/common";

export const tvEnvState = atom<TvEnvTypes>({
  key: "tvEnvState",
  default: {
    SUBSIDIARY: "",
    DUID: "",
    DEVICE_ID: "",
    DEVICE_MODEL: "",
    SCENARIO: "oled",
    RETAILER: "",
    PDP: "",
    STORE: "",
    TVNAME:"",
    SOCKET_URL:""
  },
});
