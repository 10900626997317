export enum STEP {
  // picture
  HDR = "hdr",
  MATRIX = "matrix",

  // oled
  BLACKDETAIL = "blackDetail",
  COLOR = "color",

  // sound
  OTSPLUS = "otsPlus",
  QSYMPHONY = "qSymphony",
  HOMECINEMA = "homeCinema",

  // common
  COVER = "cover",
  OUTRO = "outro",
  SMARTTHINGS = "smartThings",
}
