import { memo, useEffect, useState } from "react";
import { UnderButton, UnderContainer, UnderLine, VolumeButton, VolumeContainer } from "./index.style";
import { MinusIcon, PlusIcon, SoundIcon } from "constants/icons";
import { useTheme } from "core/contexts/ThemeContext";
import { useSocket } from "core/contexts/SocketContext";
import { useRecoilValue } from "recoil";
import { stepState } from "core/store/stepState";

const Volume = () => {
  const [isUnderOpen, setIsUnderOpen] = useState<boolean | null>(null);
  const { theme } = useTheme();
  const { volumeUp, volumeDown } = useSocket();
  const step = useRecoilValue(stepState);

  useEffect(() => {
    setIsUnderOpen(false);
  }, [step]);

  return (
    <VolumeContainer>
      <VolumeButton
        onClick={() => {
          setIsUnderOpen(!isUnderOpen);
        }}
      >
        <SoundIcon color={theme.color.icon.onBackground} />
      </VolumeButton>
      {isUnderOpen !== null && (
        <UnderContainer $isUnderOpen={isUnderOpen}>
          <UnderButton onClick={volumeUp}>
            <PlusIcon color={theme.color.icon.soundButton} />
          </UnderButton>
          <UnderLine />
          <UnderButton onClick={volumeDown}>
            <MinusIcon color={theme.color.icon.soundButton} />
          </UnderButton>
        </UnderContainer>
      )}
    </VolumeContainer>
  );
};

export default memo(Volume);
