export enum COMMANDS {
  /// COMMON
  // SIZE SIMULATOR
  SIZE_SIMULATOR_START = "SIZE_SIMULATOR_START",
  SIZE_SIMULATOR_END = "SIZE_SIMULATOR_END",

  VOLUME_UP = "VOLUME_UP",
  VOLUME_DOWN = "VOLUME_DOWN",

  /// EXPERIENCE
  // 체험 단계별 시작 step 입력 받고 duration 반환
  SCENARIO_START = "SCENARIO_START",
  SCENARIO_SKIP = "SCENARIO_SKIP",
  SCENARIO_BRIDGE = "SCENARIO_BRIDGE",
  SCENARIO_EXPERIENCE = "SCENARIO_EXPERIENCE",

  EXPERIENCE_DATA = "EXPERIENCE_DATA",
}
