import { zindex } from "constants/theme";
import { typoSharpSansBold, typoSharpSansRegular } from "constants/theme/fonts";
import styled, { css } from "styled-components";

export const CompleteLayoutTitle = styled.div`
  width: calc(100% - 40px);
  margin: 30px 20px;

  ${typoSharpSansBold}
  font-size: 20px;
  line-height: 100%;

  > div {
    margin-top: 10px;
  }
`;
export const CompleteTextContainer = styled.div`
  margin-bottom: 48px;

  > * {
    text-align: center;
    ${typoSharpSansRegular}
  }

  > div:nth-of-type(1) {
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 125%;

    > div {
      ${typoSharpSansBold}
    }
  }
`;

export const CompleteBackground = styled.img<{ $isSound: boolean }>`
  position: absolute;
  top: 0;
  z-index: -1;

  ${(props) =>
    props.$isSound
      ? css`
          left: -140px;
          width: auto;
        `
      : css`
          width: 100vw;
        `}
  height: 100%;
`;

export const CompleteLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - 80px);

  > button {
    width: 139px;
    padding: 14px 10px;

    border: none;
    border-radius: 23px;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 1.4px;
  }

  > * {
    position: relative;
    top: -60px;
  }
`;

export const CompleteLayoutWraper = styled.div`
  position: relative;
  z-index: ${zindex.cover};
`;
