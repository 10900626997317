import { useEffect, useState } from "react";

import { useSocket } from "core/contexts/SocketContext";
import { useTheme } from "core/contexts/ThemeContext";
import { useFunnel } from "core/hooks/useFunnel";

import StartLayout from "layout/StartLayout";
import CoverLayout from "layout/CoverLayout";

import { EVENTS } from "constants/enum/events";
import { scenarioTheme } from "constants/scenarioTheme";
import { Container } from "constants/theme/globalStyles";
import { COMMANDS } from "constants/enum/commands";
import CompareModal from "layout/Modal/CompareModal";
import { STEP } from "constants/enum/step";
import { useRecoilState } from "recoil";
import { stepState } from "core/store/stepState";
import useTimeout from "core/hooks/useTimer";
import { SCENARIO_STEP } from "constants/enum/scenarioStep";
import CongratulationLayout from "layout/CongratulationLayout";
import HomecinemaModal from "layout/Modal/HomecinemaModal";
import OtsProModal from "layout/Modal/OtsProModal";
import ExperienceCoach from "layout/CoachLayout/ExperienceCoach";
import CongratulationCoach from "layout/CoachLayout/CongratulationCoach";
import { useUrlQuery } from "core/hooks/useUrlQuery";
import { postMobileStartExp } from "core/util/api/tvApi";

const Sound = () => {
  const [step, setStepState] = useRecoilState(stepState);

  const [playTime, setPlayTime] = useState<number>(-1);

  const { theme, setTheme } = useTheme();
  const { clear, setAsync } = useTimeout();
  const { socket, scenarioStep, scenarioSkip, scenarioBridge, scenarioExperience } = useSocket();
  const [Funnel, setStep] = useFunnel([...theme.StepList] as const, STEP.COVER);
  const [ExperienceFunnel, setExperienceStep] = useFunnel([...theme.ExperienceStepList] as const, SCENARIO_STEP.START);
  const [isCoach, setIsCoach] = useState<boolean>(false);

  const query = useUrlQuery(["duid"] as const);

  const coachOpen = () => setIsCoach(true);

  const coachClose = () => setIsCoach(false);

  const startSkipHandler = (step: STEP) => {
    setPlayTime(-1);
    setStepState({ step, scenarioStep: SCENARIO_STEP.SKIP });
    scenarioSkip(step);
  };

  const goBridge = (step: STEP) => {
    setStepState({ step, scenarioStep: SCENARIO_STEP.BRIDGE });
    scenarioBridge(step);
    setPlayTime(-1);
  };

  const nextExperience = (step: STEP) => {
    scenarioStep(step);
    setStepState({ step, scenarioStep: SCENARIO_STEP.START });
  };

  /// 스텝으로 이동  ------------------------------------------------------------
  useEffect(() => {
    clear();
    setPlayTime(-1);
    setStep(step.step as any);
    setExperienceStep(step.scenarioStep as any);
    coachOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    setTheme(scenarioTheme.sound);
    const socketListener = async (data: any) => {
      switch (data.command) {
        case COMMANDS.SCENARIO_START:
          setPlayTime(Math.ceil(data.duration / 1000));
          break;
        case COMMANDS.SCENARIO_BRIDGE:
          await setAsync(data.duration);
          setPlayTime(-1);
          setStepState((prev) => ({
            ...prev,
            scenarioStep: SCENARIO_STEP.EXPERIENCE,
          }));
          scenarioExperience(data.step);
          break;
        default:
          break;
      }
    };
    socket.on(EVENTS.SCENARIO_TV_TO_MOBILE, socketListener);
    return () => {
      socket.off(EVENTS.SCENARIO_TV_TO_MOBILE, socketListener);
      setStepState({ step: STEP.COVER, scenarioStep: SCENARIO_STEP.START });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Funnel>
        {theme.StepList.map((step, index) => {
          const layoutInfo = (theme.StartLayouts as any)[step];
          return (
            <Funnel.Step name={step}>
              {step === STEP.COVER ? (
                <CoverLayout
                  startHandler={() => {
                    postMobileStartExp(query.duid,"sound");
                    nextExperience(theme.StepList[index + 1]);
                  }}
                />
              ) : step === STEP.OUTRO ? (
                <>
                  {isCoach && <CongratulationCoach coachHandler={coachClose} experience="sound" />}
                  <CongratulationLayout experience="sound" />
                </>
              ) : (
                <ExperienceFunnel>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.START}>
                    <StartLayout
                      layoutInfo={layoutInfo}
                      isSkip={true}
                      time={playTime}
                      buttonHandler={() => startSkipHandler(step)}
                      endTimerHandler={() => startSkipHandler(step)}
                    />
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.SKIP}>
                    {isCoach && <ExperienceCoach coachHandler={coachClose} />}
                    <StartLayout
                      layoutInfo={layoutInfo}
                      isSkip={false}
                      isExperience={false}
                      buttonHandler={() => goBridge(step)}
                    />
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.BRIDGE}>
                    <StartLayout layoutInfo={layoutInfo} isSkip={false} isExperience={true} />
                  </ExperienceFunnel.Step>
                  <ExperienceFunnel.Step name={SCENARIO_STEP.EXPERIENCE}>
                    {step === STEP.OTSPLUS ? (
                      <OtsProModal
                        key={STEP.OTSPLUS}
                        isModalOpen={true}
                        modalCloseHandler={async () => {
                          coachClose();
                          await setAsync(800);
                          nextExperience(theme.StepList[index + 1]);
                        }}
                      />
                    ) : step === STEP.QSYMPHONY ? (
                      <CompareModal
                        key={STEP.QSYMPHONY}
                        isModalOpen={true}
                        startLayouts={layoutInfo}
                        modalCloseHandler={async () => {
                          await setAsync(800);
                          nextExperience(STEP.HOMECINEMA);
                        }}
                      />
                    ) : (
                      step === STEP.HOMECINEMA && (
                        <HomecinemaModal
                          key={STEP.HOMECINEMA}
                          isModalOpen={true}
                          modalCloseHandler={async () => {
                            coachClose();
                            await setAsync(800);
                            nextExperience(STEP.OUTRO);
                          }}
                        />
                      )
                    )}
                  </ExperienceFunnel.Step>
                </ExperienceFunnel>
              )}
            </Funnel.Step>
          );
        })}
      </Funnel>
    </Container>
  );
};

export default Sound;
