import { memo } from "react";
import { BlinkCss, CoachContainer, ExperienceContainer, ExperienceGuide } from "./Index.style";
import Button from "components/common/DefaultBtn";
import Pointed from "./Pointed";
import { css } from "styled-components";
import { CoachProps } from "types/common";
import { useTranslation } from "react-i18next";
import useHeight from "core/hooks/useHeight";

const ExperienceCoach = ({ coachHandler }: CoachProps) => {
  const { t } = useTranslation();
  const { isUnder } = useHeight();

  return (
    <CoachContainer onClick={coachHandler}>
      <ExperienceContainer $isUnder={isUnder}>
        <ExperienceGuide>{t("coach_experience_guide")}</ExperienceGuide>
        <Button handler={() => {}}>{t("common_experience")}</Button>
        <Pointed
          positionCss={css`
            bottom: 45px;
            left: calc(50vw + 34px);

            ${BlinkCss}
          `}
        />
      </ExperienceContainer>
    </CoachContainer>
  );
};

export default memo(ExperienceCoach);
