import { typoSharpSansBold } from "constants/theme/fonts";
import styled from "styled-components";

export const HeaderTitle = styled.div`
  ${typoSharpSansBold}
  font-size: 20px;
  line-height: 25px;
`;

export const HeaderButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: calc(100% - 20px * 2);
  height: fit-content;
  padding: 26.5px 20px 0;
`;
