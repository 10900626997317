import axios from "axios";
import { ApiResponse } from "types/api/apiType";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
});

/**
 * GET METHOD
 * @param {string} url TargetUrl
 * @return 통신 성공 여부와 그에 따른 데이터
 */
export const getAyncs = async (url: string): Promise<ApiResponse> => {
  const response = await axiosInstance
    .get<ApiResponse>(url)
    .then((res) => {
      return { isSuccess: true, data: res.data };
    })
    .catch((error: any) => {
      return { isSuccess: false, data: error };
    });

  return response;
};

/**
 * POST METHOD
 * @param {string} url TargetUrl
 * @param {T} body 함께 보내는 데이터
 * @return 통신 성공 여부와 그에 따른 데이터
 */
export const postAyncs = async <T>(
  url: string,
  body?: T
): Promise<ApiResponse> => {
  const response = await axiosInstance
    .post<ApiResponse>(url, body)
    .then((res) => {
      return { isSuccess: true, data: res.data };
    })
    .catch((error: any) => {
      return { isSuccess: false, data: error };
    });

  return response;
};

/**
 * PATCH METHOD
 * @param {string} url TargetUrl
 * @param {T} body 함께 보내는 데이터
 * @return 통신 성공 여부와 그에 따른 데이터
 */
export const patchAyncs = async <T>(
  url: string,
  body?: T
): Promise<ApiResponse> => {
  const response = await axiosInstance
    .patch<ApiResponse>(url, body)
    .then((res) => {
      return { isSuccess: true, data: res.data };
    })
    .catch((error: any) => {
      return { isSuccess: false, data: error };
    });

  return response;
};

/**
 * DELETE METHOD
 * @param {string} url TargetUrl
 * @return 통신 성공 여부와 그에 따른 데이터
 */
export const deleteAyncs = async (url: string): Promise<ApiResponse> => {
  const response = await axiosInstance
    .delete<ApiResponse>(url)
    .then((res) => {
      return { isSuccess: true, data: res.data };
    })
    .catch((error: any) => {
      return { isSuccess: false, data: error };
    });

  return response;
};
